// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import { appSlice } from './App.slice';
import loggerMiddleware from './App.loggerMiddleware';

// Configure the Redux store
export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
  },

  //   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(loggerMiddleware), // Apply the logger middleware

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disables the check globally
    }).concat(loggerMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
