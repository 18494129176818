/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react';
import { showToast } from 'spoton-lib';

import { TOAST_CONTAINER_ID } from 'features/common/constants/toast.constants';

import { StringToDataSourceDict, SpotOnTanstackColDefs } from '../types';
import { datadogRum } from '@datadog/browser-rum';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface UseDataCatalogProps {
  startDate: Date | null;
  endDate: Date | null;
  dataCatalog: StringToDataSourceDict;
  dataSource: string;
  columns?: string[];
  location: string;
}

export function useDataCatalog({
  startDate,
  endDate,
  dataCatalog,
  dataSource,
  columns = [],
  location,
}: UseDataCatalogProps) {
  const [rows, setRows] = useState<any[]>([]);
  const [chosenColumnsFromMetadata, setChosenColumnsFromMetadata] = useState<string[]>([]);
  const [columnOrderFromMetadata, setColumnOrderFromMetadata] = useState<string[]>([]);
  const [meta, setMeta] = useState<SpotOnTanstackColDefs<any>[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function getRows() {
      try {
        setError(false);
        setTableLoading(true);

        const rowDataWithoutGlobalId = (await dataCatalog[dataSource]?.getRows(
          startDate,
          endDate,
          columns,
          location,
        )) as any[];

        // Here we create a globalId so we can perform a implicit groupby.
        // This is useful to get a single grand total row, when no grouping columns are chosen.
        const rowData = rowDataWithoutGlobalId?.map((i) => ({ ...i, globalId: 1 }));

        setRows((rowData as any[]) ?? []);

        const metadata = await dataCatalog[dataSource]?.getMeta();

        setMeta(metadata);
        setTableLoading(false);

        // sort the columns using so_sortIndex
        const orderedColumns = metadata.sort((a: SpotOnTanstackColDefs<any>, b: SpotOnTanstackColDefs<any>) => {
          if (a.so_sortIndex != null && b.so_sortIndex != null) {
            return a.so_sortIndex - b.so_sortIndex;
          }

          return 0;
        });

        const chosenColumns = orderedColumns
          .filter((i: SpotOnTanstackColDefs<any>) => i.so_visibleByDefault)
          .map((i: SpotOnTanstackColDefs<any>) => i.accessorKey as string);

        setChosenColumnsFromMetadata(chosenColumns);

        setColumnOrderFromMetadata(orderedColumns.map((i: SpotOnTanstackColDefs<any>) => i.accessorKey as string));
      } catch (e: any) {
        setTableLoading(false);

        showToast({
          variant: 'danger',
          content: `There was an unexpected error. Please try again. ${e.message}`,
          position: 'top-center',
          containerId: TOAST_CONTAINER_ID,
          buttonText: '',
          limit: 2,
        });

        setError(true);
        console.error(e);
      }
    }

    if (dataCatalog && dataSource && startDate && endDate) {
      if (performance != null && (performance as any).memory != null) {
        datadogRum.addAction('preQueryMemoryMetrics', (performance as any).memory);
      } else {
        console.log('performance.memory is not supported in this browser.');
      }

      getRows();

      if (performance != null && (performance as any).memory != null) {
        datadogRum.addAction('postQueryMemoryMetrics', (performance as any).memory);
        const memoryUsage = (performance as any).memory;

        console.log(`Post Query JS Heap Size Limit: ${memoryUsage.jsHeapSizeLimit / 1024 / 1024} MB`);
        console.log(`Post Query Total JS Heap Size: ${memoryUsage.totalJSHeapSize / 1024 / 1024} MB`);
        console.log(`Post Query Used JS Heap Size: ${memoryUsage.usedJSHeapSize / 1024 / 1024} MB`);
      } else {
        console.log('performance.memory is not supported in this browser.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, dataCatalog, dataSource, location]);

  return {
    rows,
    meta,
    chosenColumnsFromMetadata,
    columnOrderFromMetadata,
    tableLoading,
    error,
  };
}
