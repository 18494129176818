import axios from 'axios';

import { getCoreLoginBaseUrl, getBffBaseUrl } from 'features';
import { HTTP_STATUS_CODES } from 'utils/constants';

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

const constructBaseUrl = (url: string) => `${url}/api`;

const createApi = (baseURL?: string) => {
  return axios.create({
    baseURL: baseURL,
    withCredentials: true,
  });
};

export const API = createApi();

export const configureApi = (useEphemeralBaseUrl: boolean) => {
  const BFF_BASE_URL = getBffBaseUrl(useEphemeralBaseUrl);
  const CORE_LOGIN_BASE_URL = getCoreLoginBaseUrl(useEphemeralBaseUrl);

  API.defaults.baseURL = constructBaseUrl(BFF_BASE_URL);

  API.interceptors.response.use(
    (response) => response,
    (data) => {
      if (data.response?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
        const nextUrl = window.location.href;
        window.location.href = `${CORE_LOGIN_BASE_URL}/home?next=${nextUrl}`;
      }

      return Promise.reject(data);
    },
  );
};
