// src/features/counter/appSlice.js
import { createSlice, current } from '@reduxjs/toolkit';
import {
  customViews,
  dataSources,
  locations,
  saveCustomView,
  bulkRemoveCustomViews,
  bulkUpdateCustomViews,
} from './App.hunks';
import moment from 'moment';

// TODO: remove this
import { getDemoCustomViews } from 'features/custom_views/utils/customViews';
import { getConfigVar } from 'features/common';
import { FAKE_CUSTOM_VIEWS_DATA, isFeatureFlagEnabled } from 'features/common/utils/featureFlags';

type DataSourceSelectionMode = 'useTemplates' | 'useCustom';

type ColumnChooserMode = 'new' | 'edit';

const getColumnOrderFromMetadata = (metadata: any) => {
  const sorted = metadata.sort((a, b) => {
    if (a.so_sortIndex === b.so_sortIndex) {
      return 0;
    }

    return a.so_sortIndex > b.so_sortIndex ? 1 : -1;
  });

  return sorted.map((i: any) => i.accessorKey);
};

const getChosenColumnsFromMetadata = (metadata: any) => {
  return metadata.filter((i: any) => i.so_visibleByDefault).map((i: any) => i.accessorKey);
};

// Initial state
const initialState = {
  // writing to this will trigger a refresh of the custom views
  customViewsLandingRefresh: 0,

  customViewsData: [],
  customViewsLoading: false,
  customViewsError: null,
  customViewsLoaded: false,

  saveCustomViewLoading: false,
  saveCustomViewData: [],
  saveCustomViewError: null,

  bulkUpdateCustomViewsLoading: false,
  bulkUpdateCustomViewsData: [],
  bulkUpdateCustomViewsError: null,

  bulkRemoveCustomViewsLoading: false,
  bulkRemoveCustomViewsData: [],
  bulkRemoveCustomViewsError: null,

  dataSourcesData: [],
  dataSourcesLoading: false,
  dataSourcesError: null,
  dataCatalog: {},

  availableDataSources: [],

  locationsData: [],
  locationsLoading: false,
  locationsError: null,

  chosenLocation: '',

  // Column chooser state (when user saves this gets applied to currentCustomView) )
  // if the user has a currentCustomView (url params)
  // then this will be overwritten by the currentCustomView
  // but it will start out as the default template.

  dataSourceSelectionMode: 'useTemplates' as DataSourceSelectionMode,
  createNewCustomViewModalOpened: false,
  chosenDataSource: undefined,
  chosenTemplate: '1',
  availableTemplates: [],
  columnOrder: [],
  chosenColumns: [],
  columnChooserMode: 'new' as ColumnChooserMode,

  // this will be metadata by datasource
  metadataBySource: {},

  // this gets set when we select either an existing custom view, or a new custom view.
  currentCustomView: {
    id: null,
    startDate: null,
    dateCreated: null,
    endDate: null,
    name: '',
    dataSource: null,
    chosenColumns: null,
    description: null,
    emailList: [],
    categoryList: [],
    selectedRange: '',
    columnOrder: [],
    filters: [],
    columnSort: [],
    location: null,
  },

  merchantId: null,
};

// Create the slice
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMerchantId: (state, action) => {
      state.merchantId = action.payload;
    },
    openDataSourceConfiguration: (state, action) => {
      state.createNewCustomViewModalOpened = true;

      state.columnChooserMode = action.payload;

      // we initialize chosenColumns
      // columnOrder for the modal
      if (action.payload === 'new') {
        // here we initialize the chosen columns and column order for the modal
        // by using either the metadata or the template

        if (state.dataSourceSelectionMode === 'useCustom') {
          // LIKELY DEAD CODE BECAUSE WE DEFAULT TO USE TEMPLATES when user chooses a new custom view
          // columns chooser state is derived  from metadata'
          const metadata = JSON.parse(JSON.stringify(state.metadataBySource[state.chosenDataSource]));
          state.columnOrder = getColumnOrderFromMetadata(metadata);
          state.chosenColumns = getChosenColumnsFromMetadata(metadata);
        } else {
          // useTemplate mode.
          // column chooser state is derived from the template
          const customView = state.customViewsData.find((i) => i.id === state.chosenTemplate);
          state.chosenDataSource = customView?.dataSource;

          const metadata = JSON.parse(JSON.stringify(state.metadataBySource[state.chosenDataSource]));

          // TODO: diff the column order from the metadata and the custom view
          // so that new columns can be appended to the end of the column order

          const defaultColumnOrder = getColumnOrderFromMetadata(metadata);

          // get all columns in default that are not in the custom views column order
          const customViewColumnOrderSet = new Set(customView.columnOrder);

          const diff = defaultColumnOrder.filter((i) => !customViewColumnOrderSet.has(i));

          state.columnOrder = [...customView.columnOrder, ...diff];
          state.chosenColumns = customView?.chosenColumns;
        }
      } else {
        // user is editing an existing custom view
        // so we need to initialize the chosen columns and column order
        // from the currentCustomView

        const metadata = JSON.parse(JSON.stringify(state.metadataBySource[state.currentCustomView.dataSource]));

        const defaultColumnOrder = getColumnOrderFromMetadata(metadata);

        // get all columns in default that are not in the custom views column order
        const customViewColumnOrderSet = new Set(state.currentCustomView.columnOrder);
        const diff = defaultColumnOrder.filter((i) => !customViewColumnOrderSet.has(i));

        state.columnOrder = [...state.currentCustomView.columnOrder, ...diff];
        state.chosenColumns = state.currentCustomView.chosenColumns;
        state.chosenDataSource = state.currentCustomView.dataSource;
        state.dataSourceSelectionMode = 'useCustom';
      }
    },
    setDataSourceSelectionMode: (state, action) => {
      state.dataSourceSelectionMode = action.payload;

      if (state.dataSourceSelectionMode === 'useCustom') {
        // columns chooser state is derived  from metadata'
        const metadata = JSON.parse(JSON.stringify(state.metadataBySource[state.chosenDataSource]));
        state.columnOrder = getColumnOrderFromMetadata(metadata);
        state.chosenColumns = getChosenColumnsFromMetadata(metadata);
      } else {
        if (state.chosenTemplate == null) {
          state.chosenTemplate = '1';
        }
        // column chooser state is derived from the template+metadata
        const customView = state.customViewsData.find((i) => i.id === state.chosenTemplate);

        state.chosenDataSource = customView?.dataSource;

        const metadata = JSON.parse(JSON.stringify(state.metadataBySource[state.chosenDataSource]));
        const defaultColumnOrder = getColumnOrderFromMetadata(metadata);
        const customViewColumnOrderSet = new Set(customView.columnOrder);
        const diff = defaultColumnOrder.filter((i) => !customViewColumnOrderSet.has(i));

        state.columnOrder = [...customView.columnOrder, ...diff];

        state.chosenColumns = customView?.chosenColumns;
      }
    },
    setName: (state, action) => {
      state.currentCustomView.name = action.payload;
    },

    setColumnSort: (state, action) => {
      state.currentCustomView.columnSort = action.payload;
    },

    setLocation: (state, action) => {
      state.currentCustomView.location = action.payload;
    },
    setChosenTemplate: (state, action) => {
      state.chosenTemplate = action.payload;
      const customView = state.customViewsData.find((i) => i.id === action.payload);

      state.chosenColumns = customView?.chosenColumns;
      state.chosenDataSource = customView?.dataSource;

      const metadata = JSON.parse(JSON.stringify(state.metadataBySource[state.chosenDataSource]));
      const defaultColumnOrder = getColumnOrderFromMetadata(metadata);
      const customViewColumnOrderSet = new Set(customView.columnOrder);
      const diff = defaultColumnOrder.filter((i) => !customViewColumnOrderSet.has(i));

      state.columnOrder = [...customView.columnOrder, ...diff];
    },
    setChosenDataSource: (state, action) => {
      state.chosenDataSource = action.payload;
      const metadata = JSON.parse(JSON.stringify(state.metadataBySource[action.payload]));
      state.columnOrder = getColumnOrderFromMetadata(metadata);
      state.chosenColumns = getChosenColumnsFromMetadata(metadata);
    },
    setColumnOrder: (state, action) => {
      // when modal is opened we save to temporary state
      if (state.createNewCustomViewModalOpened) {
        state.columnOrder = action.payload;
      } else {
        // modal not open so we save to currentCustomView
        state.currentCustomView.columnOrder = action.payload;
      }
    },

    setChosenColumns: (state, action) => {
      state.chosenColumns = action.payload;
    },

    closeColumnChooserModal: (state) => {
      state.createNewCustomViewModalOpened = false;
    },
    saveColumnChooserConfig: (state) => {
      if (state.columnChooserMode === 'new') {
        // we just copy over the settings created in the modal to currentCustomView
        // and initialize custom view
        if (state.dataSourceSelectionMode === 'useCustom') {
          state.currentCustomView.id = 'new';

          state.currentCustomView.startDate = moment().subtract(30, 'days').toDate();
          state.currentCustomView.endDate = moment().toDate();

          state.currentCustomView.name =
            state.dataSourceSelectionMode === 'useCustom'
              ? 'New Custom View (click to change)'
              : `Copy of ${state.chosenTemplate}`;

          state.currentCustomView.dataSource = state.chosenDataSource;
          state.currentCustomView.chosenColumns = state.chosenColumns;
          state.currentCustomView.description = '';
          state.currentCustomView.emailList = [];
          state.currentCustomView.categoryList = [];
          state.currentCustomView.selectedRange = 'custom';
          state.currentCustomView.columnOrder = state.columnOrder;
          state.currentCustomView.categoryList = [];
          state.currentCustomView.filters = [];
        } else {
          // user chose to use a template so copy the template over and override the chosen columns and column order
          // that were selected in the modal
          const template = state.customViewsData.find((i) => i.id === state.chosenTemplate);

          state.currentCustomView = {
            ...template,
            id: 'new',
            chosenColumns: state.chosenColumns,
            columnOrder: state.columnOrder,
            name: `Copy of ${template.name}`,
          };
        }
      } else {
        // editing a custom view

        const needsReload = state.chosenDataSource !== state.currentCustomView.dataSource;

        state.currentCustomView.dataSource = state.chosenDataSource;
        state.currentCustomView.chosenColumns = state.chosenColumns;
        state.currentCustomView.columnOrder = state.columnOrder;

        if (needsReload) {
          localStorage.setItem(
            'currentCustomView',
            JSON.stringify(
              {
                timestamp: Date.now(),
                data: { ...state.currentCustomView },
              },
              null,
              4,
            ),
          );

          window.location.assign(`/custom-views/custom-view/${state.currentCustomView.id}?useLocalStorage=1`);
        }
      }

      state.createNewCustomViewModalOpened = false;
    },
    setDateRange: (state, action) => {
      const { startDate, endDate, selectedRange } = action.payload;
      state.currentCustomView.startDate = startDate;
      state.currentCustomView.endDate = endDate;
      state.currentCustomView.selectedRange = selectedRange;
    },

    setFilters: (state, action) => {
      state.currentCustomView.filters = action.payload;
    },

    setCurrentCustomView: (state, action) => {
      if (typeof action.payload === 'string') {
        const customView = state.customViewsData.find((i) => i.id === action.payload);
        state.currentCustomView = customView;
      } else {
        state.currentCustomView = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(customViews.pending, (state) => {
        state.customViewsLoading = true;
        state.customViewsError = null;
      })
      .addCase(customViews.fulfilled, (state, action) => {
        // here we seed the current custom view with the first template
        // but only if the current custom view is not already set.
        state.customViewsLoading = false;
        state.customViewsData = action.payload; // Data from the fulfilled async thunk
        state.customViewsError = null;
        state.customViewsLoaded = true;
        // NOTE: when doing page reloads this runs after we we set the current
        // custom view from cache. so we need to check if the current custom view
        // is not already set.
        if (state.currentCustomView.id == null) {
          const defaultCustomView = getDemoCustomViews(action.payload)[0];

          if (defaultCustomView != null) {
            state.currentCustomView = {
              ...defaultCustomView,
              id: undefined,
              dateCreated: undefined,
            };

            state.dataSourceSelectionMode = 'useTemplates';
            // needed for getting the metadata on the current data source

            state.currentCustomView.dataSource = defaultCustomView.dataSource;
            state.chosenDataSource = defaultCustomView.dataSource;

            // template is the first custom view
            state.chosenTemplate = defaultCustomView.id;
          }
        }

        const map = getDemoCustomViews(action.payload).map((template) => {
          return {
            label: template.name,
            value: template.id,
          };
        });

        // extract out templates from the custom views. this list contains both user created custom views and templates.
        state.availableTemplates = map;
      })

      .addCase(customViews.rejected, (state, action) => {
        state.customViewsLoading = false;
        state.customViewsError = action.payload as string; // Error from the rejected async thunk
      })

      .addCase(locations.pending, (state) => {
        state.locationsLoading = true;
        state.locationsError = null;
      })

      .addCase(locations.rejected, (state) => {
        state.locationsLoading = false;
        state.locationsError = true;
      })

      .addCase(locations.fulfilled, (state, action) => {
        state.locationsLoading = false;
        state.locationsData = action.payload; // Data from the fulfilled async thunk
        state.locationsError = null;
      })

      .addCase(bulkUpdateCustomViews.rejected, (state, action) => {
        state.bulkUpdateCustomViewsLoading = false;
        state.bulkUpdateCustomViewsError = action.payload as string; // Error from the rejected async thunk
      })

      .addCase(bulkUpdateCustomViews.pending, (state) => {
        state.bulkUpdateCustomViewsLoading = true;
        state.bulkUpdateCustomViewsError = null;
      })

      .addCase(bulkUpdateCustomViews.fulfilled, (state) => {
        state.customViewsLandingRefresh = state.customViewsLandingRefresh + 1;
        state.bulkUpdateCustomViewsLoading = false;
        state.bulkUpdateCustomViewsError = true;
      })

      .addCase(bulkRemoveCustomViews.rejected, (state, action) => {
        state.bulkRemoveCustomViewsLoading = false;
        state.bulkRemoveCustomViewsError = action.payload as string; // Error from the rejected async thunk
      })

      .addCase(bulkRemoveCustomViews.pending, (state) => {
        state.bulkRemoveCustomViewsLoading = true;
        state.bulkRemoveCustomViewsError = null;
      })

      .addCase(bulkRemoveCustomViews.fulfilled, (state) => {
        state.customViewsLandingRefresh = state.customViewsLandingRefresh + 1;
        state.bulkRemoveCustomViewsLoading = false;
        state.bulkRemoveCustomViewsError = true;
      })

      .addCase(saveCustomView.pending, (state) => {
        state.saveCustomViewLoading = true;
        state.saveCustomViewError = null;
      })

      .addCase(saveCustomView.fulfilled, (state, action) => {
        state.saveCustomViewLoading = false;
        // i dont think this will get used for anything but we'll keep it here for now
        state.saveCustomViewData = action.payload; // Data from the fulfilled async thunk

        state.saveCustomViewError = null;
        // for new custom views we need to set the id
        if (current(state.currentCustomView).id === 'new') {
          // NOTE: by changing the id, the custom view component rerenders
          // and detects this new id and navigates to the new custom view
          state.currentCustomView.id = action.payload.id;

          // here we just append the current to the stored custom views
          const viewToAdd = JSON.parse(JSON.stringify(state.currentCustomView));
          state.customViewsData = [...state.customViewsData, viewToAdd];
        } else {
          // the view was edited and saved so we need to update the custom views
          // with the new view
          const viewToAdd = JSON.parse(JSON.stringify(state.currentCustomView));
          const filteredCustomViews = state.customViewsData.filter((i) => i.id !== viewToAdd.id);
          state.customViewsData = [...filteredCustomViews, viewToAdd];
        }
      })

      .addCase(saveCustomView.rejected, (state, action) => {
        state.saveCustomViewLoading = false;
        state.saveCustomViewError = action.payload;
      })

      .addCase(dataSources.pending, (state) => {
        state.dataSourcesLoading = true;
        state.dataSourcesError = null;
      })
      .addCase(dataSources.fulfilled, (state, action) => {
        state.dataSourcesLoading = false;
        state.dataSourcesData = action.payload; // Data from the fulfilled async thunk
        state.dataSourcesError = null;

        state.metadataBySource = action.payload.reduce((acc, source) => {
          acc[source.name] = JSON.parse(source.metadata);

          return acc;
        }, {});

        // here we build the data catalog
        const dataCatalog = action.payload.reduce((acc, source) => {
          const obj = {
            name: source.name,
            getMeta: async () => {
              return Promise.resolve(JSON.parse(source.metadata));
            },
            getRows: async (startDate: string, endDate: string) => {
              const DATE_FORMAT = 'YYYYMMDD';
              const regex = /^\d{4}-\d{2}-\d{2}$/;

              let startDateFmt;

              if (regex.test(startDate)) {
                startDateFmt = startDate.replace(/-/g, '');
              } else {
                startDateFmt = moment(startDate).format(DATE_FORMAT);
              }

              let endDateFmt;

              if (regex.test(endDate)) {
                endDateFmt = endDate.replace(/-/g, '');
              } else {
                endDateFmt = moment(endDate).format(DATE_FORMAT);
              }

              const pathSuffix = isFeatureFlagEnabled(FAKE_CUSTOM_VIEWS_DATA) ? '-fake' : '';

              const path = `${getConfigVar('REACT_APP_BFF_BASE_URL')}/custom-views/data/${pathSuffix}`;

              const queryParams = new URLSearchParams({
                data_source: source.name,
                start_date: startDateFmt,
                end_date: endDateFmt,
              });

              const url = `${path}?${queryParams.toString()}`;
              try {
                const response = await fetch(url, {
                  method: 'GET',
                  credentials: 'include',
                });
                if (!response.ok) {
                  throw new Error(`Error fetching data: ${response.statusText}`);
                }

                const data = await response.json();
                return data;
              } catch (error) {
                console.error('Error running query:', error);
                throw error;
              }
            },
          };

          acc[source.name] = obj;
          return acc;
        }, {});

        state.availableDataSources = dataCatalog
          ? Object.keys(dataCatalog).map((i) => ({
              label: i,
              value: i,
            }))
          : [];

        state.dataCatalog = dataCatalog;
      })
      .addCase(dataSources.rejected, (state, action) => {
        state.dataSourcesLoading = false;
        state.dataSourcesError = action.payload as string; // Error from the rejected async thunk
      });
  },
});

// Export the actions
export const {
  setMerchantId,
  setCurrentCustomView,
  setFilters,
  setDateRange,
  setColumnSort,
  closeColumnChooserModal,
  saveColumnChooserConfig,
  setChosenColumns,
  setColumnOrder,
  setChosenDataSource,
  setChosenTemplate,
  setDataSourceSelectionMode,
  openDataSourceConfiguration,
  setName,
  setLocation,
} = appSlice.actions;
