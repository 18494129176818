// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    height: 100%;
    overflow: hidden;
}

body,
input,
select,
label,
table,
textarea {
    font-family:
        Poppins -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/App/index.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;;;IAGI,sBAAsB;AAC1B;;AAEA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,SAAS;IACT,YAAY;IACZ,gBAAgB;AACpB;;AAEA;;;;;;IAMI;;;;;;;;;;;kBAWc;;IAEd,mCAAmC;IACnC,kCAAkC;AACtC","sourcesContent":["/* Box sizing rules */\n*,\n*::before,\n*::after {\n    box-sizing: border-box;\n}\n\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\nbody {\n    margin: 0;\n    height: 100%;\n    overflow: hidden;\n}\n\nbody,\ninput,\nselect,\nlabel,\ntable,\ntextarea {\n    font-family:\n        Poppins -apple-system,\n        BlinkMacSystemFont,\n        'Segoe UI',\n        'Roboto',\n        'Oxygen',\n        'Ubuntu',\n        'Cantarell',\n        'Fira Sans',\n        'Droid Sans',\n        'Helvetica Neue',\n        sans-serif;\n\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
