/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import CreatableSelect from 'react-select/creatable';
import { Modal, Button, IconButton, colors, Input, Checkbox } from 'spoton-lib';

import styles from './CustomViewEditor.module.scss';
import { StringToBooleanDict } from '../../../common/components/SpotonDataTable/types';
import { CustomViewType } from 'features/custom_views/utils/customViews';

type CustomViewEditorProps = {
  customViews: CustomViewType[];
  open: boolean;
  onSave: (customViews: CustomViewType[]) => void;
  onClose: () => void;
};

function getConsensusValue({
  items,
  key,
  noConsensusValue = [],
}: {
  items: any[];
  key: string;
  noConsensusValue?: string[];
}) {
  // if everything equals the first item then we have a consensus. so we can just find
  // some item that is not equal to the first item.

  let rval = noConsensusValue;

  if (items.length > 0) {
    const firstItem = items[0];
    const ret = items.find((i) => JSON.stringify(i[key]) !== JSON.stringify(firstItem[key]));

    if (ret == null) {
      rval = firstItem![key];
    }
  }

  return rval;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function CustomViewEditor({ customViews, open, onClose, onSave }: CustomViewEditorProps) {
  const [name, setName] = useState(customViews[0].name);

  const [selectedCategories, setSelectedCategories] = useState(
    customViews.length === 1
      ? customViews[0].categoryList
      : getConsensusValue({
          items: customViews as any[],
          key: 'categoryList',
        }),
  );

  // const [selectedEmails, setSelectedEmails] = useState(
  //   customViews.length === 1
  //     ? customViews[0].emailList
  //     : getConsensusValue({
  //         items: customViews as any[],
  //         key: 'emailList',
  //       }),
  // );
  const [multiEditCheckState, setMultiEditCheckState] = useState<StringToBooleanDict>({});

  const [description, setDescription] = useState(customViews[0].description);

  const setMultiEditCheckStateVal = (inputName: string, val: any) => {
    const mergedObj = { ...multiEditCheckState, ...{ [inputName]: val } };
    setMultiEditCheckState(mergedObj);
  };

  const isMultiEditChecked = (inputName: string) => {
    const ret = multiEditCheckState[inputName] === true;
    return ret;
  };

  const nameInput = (
    <Input
      label={'Custom View Name'}
      value={name}
      onChange={(e) => {
        setName(e.target.value);
      }}
      name="viewName"
    />
  );

  const descriptionInput = (
    <Input
      label={'Custom View Description'}
      value={description}
      onChange={(e) => {
        setDescription(e.target.value);
      }}
      name="viewDescription"
    />
  );

  const categoryPickerInput = (
    <div className={styles.FormLabelInputPair}>
      <label className={styles.Label} htmlFor="group-by">
        Categories
      </label>
      <CreatableSelect
        className={styles.CategoryPicker}
        isMulti
        isClearable
        value={selectedCategories?.map((i: any) => ({
          label: i,
          value: i,
        }))}
        onChange={(e) => {
          // implicit check being done here.
          setMultiEditCheckStateVal('category', true);
          setSelectedCategories(e.map((i) => i.value));
        }}
      />
    </div>
  );

  // const shareWithPickerInput = (
  //   <div className={styles.FormLabelInputPair}>
  //     <label className={styles.Label} htmlFor="group-by">
  //       Share With
  //     </label>
  //     <CreatableSelect
  //       className={styles.ShareWithPicker}
  //       isMulti
  //       isClearable
  //       value={selectedEmails?.map((i: any) => ({
  //         label: i,
  //         value: i,
  //       }))}
  //       onChange={(e) => {
  //         // implicit check being done here.
  //         setMultiEditCheckStateVal('emailList', true);
  //         setSelectedEmails(e.map((i) => i.value));
  //       }}
  //     />
  //   </div>
  // );
  function wrapSingleEdit(className: string, control: any) {
    return <div className={className}>{control}</div>;
  }
  function wrapMultiEdit(className: string, inputName: string, control: any) {
    return (
      <div className={className}>
        <div className={styles.Parent}>
          <div className={styles.MultiEditLayoutControlContainer}>{control}</div>
          <div className={styles.MultiEditLayoutCheckboxContainer}>
            <Checkbox
              data-testid="MultiEditCheckbox"
              checked={isMultiEditChecked(inputName)}
              className={`${styles.Checkbox} ${styles.Checkbox___rightMargin}`}
              onChange={({ target }) => {
                setMultiEditCheckStateVal(inputName, (target as any).checked);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  const saveCustomViews = () => {
    const viewsToSave = [];

    if (customViews.length === 1) {
      const newCustomView: CustomViewType = {
        ...customViews[0],
        name,
        description,
        // emailList: selectedEmails,
        categoryList: selectedCategories,
      };

      viewsToSave.push(newCustomView);
    } else {
      customViews.forEach((customView) => {
        const newCustomView: CustomViewType = {
          ...customView,

          ...(multiEditCheckState.category === true && {
            categoryList: selectedCategories,
          }),
          // ...(multiEditCheckState.emailList === true && {
          //   emailList: selectedEmails,
          // }),
        };

        viewsToSave.push(newCustomView);
      });
    }
    onSave(viewsToSave);
    // onClose();
  };

  return (
    <Modal isOpen={open} overlayClassName={styles.ModalOverlay} className={styles.EditcustomViewsModal}>
      <div className={styles.EditCustomViewsModal_header}>
        <div className={styles.HeaderTitle}>Edit Custom View</div>
        <IconButton disableBorder size={24} color={colors.black} onClick={onClose} name="CloseIcon" alt={'CLOSE'} />
      </div>
      <div className={styles.EditCustomViewsModal_subtitle}>
        When editing multiple Custom Views, only checked items will be saved to all selected Views.
      </div>
      <div className={styles.EditCustomViewsModal_groups}>
        <div className={styles.EditPage}>
          <div className={styles.EditInputs}>
            {customViews.length === 1 && wrapSingleEdit('', nameInput)}

            {customViews.length === 1 && wrapSingleEdit('', descriptionInput)}

            {customViews.length > 1
              ? wrapMultiEdit('vertical-input', 'category', categoryPickerInput)
              : wrapSingleEdit('vertical-input', categoryPickerInput)}
            {/* {customViews.length > 1
              ? wrapMultiEdit('vertical-input', 'emailList', shareWithPickerInput)
              : wrapSingleEdit('vertical-input', shareWithPickerInput)} */}
          </div>
        </div>
      </div>
      <div className={styles.EditCustomViewsModal_buttons}>
        <Button className={styles.Button} onClick={onClose} variant="secondary">
          CANCEL
        </Button>
        <Button disabled={false} className={styles.Button} onClick={saveCustomViews}>
          SAVE
        </Button>
      </div>
    </Modal>
  );
}
