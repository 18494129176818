import { useEffect, useRef } from 'react';

export function useOuterClick(callback: any, innerRef: any) {
  const callbackRef = useRef();

  // set current callback in ref, before second useEffect uses it
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };

    function handleClick(e: any) {
      if (innerRef.current && callbackRef.current && !(innerRef.current as any).contains(e.target)) {
        (callbackRef as any).current(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
