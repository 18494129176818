import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Checkbox, colors, IconButton, Tooltip } from 'spoton-lib';

import { StringToBooleanDict, StringToStringDict } from 'features/common/components/SpotonDataTable/types';

import styles from './DraggableCheckboxList.module.scss';

interface IDraggableCheckboxListProps {
  itemToCheckedMap: StringToBooleanDict;
  itemsOrdered: string[];
  itemInfo: StringToStringDict;
  onItemsReordered: (newItems: any[]) => void;
  onValueChecked: (col: string, checked: boolean) => void;
}

export function DraggableCheckboxList({
  itemToCheckedMap,
  itemsOrdered,
  // this is a dictionary of column name to column description for tooltips
  itemInfo,
  onItemsReordered,
  onValueChecked,
}: IDraggableCheckboxListProps) {
  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const newItems = [...itemsOrdered];
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);
    onItemsReordered(newItems);
  };

  const valueChecked = (col: any) => {
    return ({ target }: { target: any }) => {
      onValueChecked(col, target.checked);
    };
  };

  return (
    <div className={styles.DraggableCheckboxContainer}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="checkboxList">
          {(provided: any) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {itemsOrdered.map((item: string, index: number) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided: any) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <div className={styles.DraggableCheckBox}>
                        <div className={styles.CheckboxAndInfoButtonContainer}>
                          <Checkbox label={item} checked={itemToCheckedMap[item]} onChange={valueChecked(item)} />

                          {itemInfo[item] && (
                            <Tooltip
                              tooltipContent={itemInfo[item]}
                              className={styles.ToolTip}
                              variant="right"
                              hideArrow={false}
                            >
                              <IconButton
                                className={styles.IconButton}
                                disableBorder
                                size={24}
                                color={colors.black}
                                name={'InfoCircleIcon'}
                                alt={'Column Info'}
                              />
                            </Tooltip>
                          )}
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          viewBox="0 -960 960 960"
                          width="24"
                          fill={colors.base50}
                        >
                          <path d="M360-160q-33 0-56.5-23.5T280-240q0-33 23.5-56.5T360-320q33 0 56.5 23.5T440-240q0 33-23.5 56.5T360-160Zm240 0q-33 0-56.5-23.5T520-240q0-33 23.5-56.5T600-320q33 0 56.5 23.5T680-240q0 33-23.5 56.5T600-160ZM360-400q-33 0-56.5-23.5T280-480q0-33 23.5-56.5T360-560q33 0 56.5 23.5T440-480q0 33-23.5 56.5T360-400Zm240 0q-33 0-56.5-23.5T520-480q0-33 23.5-56.5T600-560q33 0 56.5 23.5T680-480q0 33-23.5 56.5T600-400ZM360-640q-33 0-56.5-23.5T280-720q0-33 23.5-56.5T360-800q33 0 56.5 23.5T440-720q0 33-23.5 56.5T360-640Zm240 0q-33 0-56.5-23.5T520-720q0-33 23.5-56.5T600-800q33 0 56.5 23.5T680-720q0 33-23.5 56.5T600-640Z" />
                        </svg>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
