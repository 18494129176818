import { Modal, Button, IconButton, colors, Dropdown, Radio } from 'spoton-lib';

import styles from './ColumnChooser.module.scss';
import { DraggableCheckboxList } from './DraggableCheckboxList';
import { StringToBooleanDict } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/components/App/App.store';
import {
  closeColumnChooserModal,
  saveColumnChooserConfig,
  setChosenColumns,
  setChosenDataSource,
  setChosenTemplate,
  setColumnOrder,
  setDataSourceSelectionMode,
} from 'app/components/App/App.slice';
import { useNavigate } from 'react-router-dom';

type ColumnChooserProps = {
  open: boolean;
};
export function ColumnChooser({ open }: ColumnChooserProps) {
  const metadataBySource = useSelector((state: RootState) => state.app.metadataBySource);
  const chosenDataSource = useSelector((state: RootState) => state.app.chosenDataSource);
  const columnChooserMode = useSelector((state: RootState) => state.app.columnChooserMode);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const dataSourceSelectionMode = useSelector((state: RootState) => state.app.dataSourceSelectionMode);

  const columnInfo = metadataBySource[chosenDataSource].reduce((acc, i) => {
    acc[i.header] = i.so_description;
    return acc;
  }, {});

  const headers = metadataBySource[chosenDataSource].map((i) => i.header);
  headers.sort();

  // build an id to label map from this metadata
  const idToLabelMap = metadataBySource[chosenDataSource].reduce((acc, i) => {
    acc[i.accessorKey] = i.header;
    return acc;
  }, {});
  // and another to convert back
  const labelToIdMap = metadataBySource[chosenDataSource].reduce((acc, i) => {
    acc[i.header] = i.accessorKey;
    return acc;
  }, {});

  const availableDataSources = useSelector((state: RootState) => state.app.availableDataSources);
  const availableTemplates = useSelector((state: RootState) => state.app.availableTemplates);
  const chosenTemplate = useSelector((state: RootState) => state.app.chosenTemplate);

  // these get wiped out when change datasoruces
  const chosenColumns = useSelector((state: RootState) => state.app.chosenColumns).map((i) => idToLabelMap[i]);
  const columnOrder = useSelector((state: RootState) => state.app.columnOrder).map((i) => idToLabelMap[i]);

  // when this modal loads we'll have this all set up in the store.
  // const chosenColumns = useSelector((state: RootState) => state.app.currentCustomView.chosenColumns);
  const chosenColumnsSet = new Set(chosenColumns);

  const colToCheckedMap: StringToBooleanDict = columnOrder.reduce(
    (acc, col) => ({ ...acc, [col]: chosenColumnsSet.has(col) }),
    {},
  );

  const checkboxState = { ...colToCheckedMap };
  const newColumnOrder = JSON.parse(JSON.stringify(columnOrder));

  const handleReorder = (newItems: any) => {
    dispatch(setColumnOrder(newItems.map((i) => labelToIdMap[i])));
  };

  const checkItem = (col: string, checked: boolean) => {
    if (checked) {
      chosenColumnsSet.add(col);
    } else {
      chosenColumnsSet.delete(col);
    }

    dispatch(setChosenColumns([...chosenColumnsSet].map((i) => labelToIdMap[i])));
  };

  const handleDataSourceSelectionModeChanged = (event) => {
    dispatch(setDataSourceSelectionMode(event.target.value));
  };

  const title = columnChooserMode === 'new' ? 'New Custom View' : 'Edit Custom View';

  return (
    <Modal isOpen={open} className={styles.ColumnChooser}>
      <div className={styles.ColumnChooser_header}>
        <div className={styles.HeaderTitle}> {title} </div>

        <IconButton
          disableBorder
          size={24}
          color={colors.black}
          onClick={() => {
            dispatch(closeColumnChooserModal());
          }}
          name="CloseIcon"
          alt={'CLOSE'}
        />
      </div>

      <div className={styles.ColumnChooser_subtitle}>
        Custom views are defined by a data source and the columns to select from the data source.
      </div>

      <form>
        <Radio
          label={'Build Custom View from Scratch'}
          checked={dataSourceSelectionMode === 'useCustom'}
          name="my-form"
          value="useCustom"
          onChange={handleDataSourceSelectionModeChanged}
        />

        <Radio
          label={'Build Custom View From Predefined Templates'}
          checked={dataSourceSelectionMode === 'useTemplates'}
          name="my-form"
          value="useTemplates"
          onChange={handleDataSourceSelectionModeChanged}
        />
      </form>

      {dataSourceSelectionMode === 'useTemplates' ? (
        <div className={styles.DataQueryPickerDropdown}>
          <Dropdown
            label="Stock Templates"
            placeholder="Stock Templates"
            data-testid="TemplatSourePicker"
            withDividers={true}
            options={availableTemplates}
            value={chosenTemplate == null ? null : availableTemplates?.find((i) => i.value === chosenTemplate)}
            onChange={(e) => {
              const item = e as { value: string };
              dispatch(setChosenTemplate(item.value));
            }}
          />
        </div>
      ) : (
        <div className={styles.DataQueryPickerDropdown}>
          <Dropdown
            label="Data Source"
            data-testid="DataSourcePicker"
            withDividers={true}
            options={availableDataSources}
            value={availableDataSources.find((i) => i.value === chosenDataSource)}
            onChange={(e) => {
              const item = e as { value: string };
              dispatch(setChosenDataSource(item.value));
            }}
          />
        </div>
      )}
      <div className={styles.ColumnCheckboxContainer}>
        <div className={styles.ColumnChooser_columnChooserLabel}>
          Select the columns you want to display in your Custom View. Drag and drop to reorder.
        </div>
        <DraggableCheckboxList
          itemInfo={columnInfo}
          itemsOrdered={newColumnOrder}
          itemToCheckedMap={checkboxState}
          onItemsReordered={handleReorder}
          onValueChecked={checkItem}
        />
      </div>
      <div className={styles.ColumnChooser_groups}></div>
      <div className={styles.ColumnChooser_buttons}>
        <Button
          className={styles.Button}
          onClick={() => {
            dispatch(closeColumnChooserModal());
          }}
          variant="secondary"
        >
          CANCEL
        </Button>
        <Button
          disabled={false}
          className={styles.Button}
          onClick={() => {
            const newChosenColumns: string[] = [];

            Object.keys(checkboxState).forEach((col) => {
              if (checkboxState[col]) {
                chosenColumnsSet.add(col);
                newChosenColumns.push(col);
              }
            });

            dispatch(saveColumnChooserConfig());

            if (columnChooserMode === 'new') {
              navigate('/custom-view/new');
            }
          }}
        >
          SAVE
        </Button>
      </div>
    </Modal>
  );
}
