/* eslint-disable no-console */
// This file was provided by Tanstack as an example on how to customize the row grouping model.
// This has a minor modification to filter the results after tanstack does its group by so that they only contain
// the parents of leaf nodes to match the output of a SQL group by function.

/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-shadow */

import { createRow, Table, Row, RowModel, RowData, flattenBy, getMemoOptions, memo } from '@tanstack/react-table';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MAX_DEPTH = 400000;

function getParentsOfLeafNodesWrapper(parentsOfLeafs: any[], leafRowSetIds: any) {
  //   const parentsOfLeafs: any[] = [];
  //   const leafRowSetIds = new Set();

  return function getParentsOfLeafNodes(row: any, parent: any, depth: number) {
    if (depth > MAX_DEPTH) {
      console.error('reached max depth', depth);
      return;
    }

    if (row.subRows.length > 0) {
      row.subRows.forEach((subRow: any) => {
        return getParentsOfLeafNodes(subRow, row, ++depth);
      });
    } else {
      // we are at a leaf node
      if (parent && !leafRowSetIds.has(parent.id)) {
        parentsOfLeafs.push(parent as never);
        leafRowSetIds.add(parent.id);
      }
    }

    return;
  };
}

export function getGroupedRowModel() {
  function getGroupedRowModelInner<TData extends RowData>(): (table: Table<TData>) => () => RowModel<TData> {
    return (table) =>
      memo(
        () => [table.getState().grouping, table.getPreGroupedRowModel()],
        (grouping, rowModel) => {
          if (!rowModel.rows.length || !grouping.length) {
            return rowModel;
          }

          // Filter the grouping list down to columns that exist
          const existingGrouping = grouping.filter((columnId) => table.getColumn(columnId));

          const hasNoGroupingColumn = existingGrouping.some(
            //@ts-expect-error "cant extend attributes on tanstack column def"
            (columnId) => table.getColumn(columnId)?.columnDef.so_uniq_val_no_grouping_applied,
          );
          if (hasNoGroupingColumn) {
            // console.log('No grouping applied');
            return rowModel;
          }

          const groupedFlatRows: Row<TData>[] = [];
          const groupedRowsById: Record<string, Row<TData>> = {};

          // Recursively group the data
          const groupUpRecursively = (rows: Row<TData>[], depth = 0, parentId?: string) => {
            // Grouping depth has been been met
            // Stop grouping and simply rewrite thd depth and row relationships
            if (depth >= existingGrouping.length) {
              return rows.map((row) => {
                row.depth = depth;

                groupedFlatRows.push(row);
                groupedRowsById[row.id] = row;

                if (row.subRows) {
                  row.subRows = groupUpRecursively(row.subRows, depth + 1, row.id);
                }

                return row;
              });
            }

            const columnId: string = existingGrouping[depth] ?? '';

            // Group the rows together for this level
            const rowGroupsMap = groupBy(rows, columnId);

            // Peform aggregations for each group
            const aggregatedGroupedRows = Array.from(rowGroupsMap.entries()).map(
              ([groupingValue, groupedRows], index) => {
                let id = `${columnId}:${groupingValue}`;
                id = parentId ? `${parentId}>${id}` : id;

                // First, Recurse to group sub rows before aggregation
                const subRows = groupUpRecursively(groupedRows, depth + 1, id);

                // Flatten the leaf rows of the rows in this group
                const leafRows = depth ? flattenBy(groupedRows, (row) => row.subRows) : groupedRows;

                const row = createRow(table, id, leafRows[0]?.original, index, depth, undefined, parentId);

                Object.assign(row, {
                  groupingColumnId: columnId,
                  groupingValue,
                  subRows,
                  leafRows,
                  getValue: (columnId: string) => {
                    // Don't aggregate columns that are in the grouping
                    if (existingGrouping.includes(columnId)) {
                      if (row._valuesCache.hasOwnProperty(columnId)) {
                        return row._valuesCache[columnId];
                      }

                      if (groupedRows[0]) {
                        row._valuesCache[columnId] = groupedRows[0].getValue(columnId) ?? undefined;
                      }

                      return row._valuesCache[columnId];
                    }

                    if (row._groupingValuesCache.hasOwnProperty(columnId)) {
                      return row._groupingValuesCache[columnId];
                    }

                    // Aggregate the values
                    const column = table.getColumn(columnId);
                    const aggregateFn = column?.getAggregationFn();

                    if (aggregateFn) {
                      row._groupingValuesCache[columnId] = aggregateFn(columnId, leafRows, groupedRows);

                      return row._groupingValuesCache[columnId];
                    }
                  },
                });

                subRows.forEach((subRow) => {
                  groupedFlatRows.push(subRow);
                  groupedRowsById[subRow.id] = subRow;
                });

                return row;
              },
            );
            return aggregatedGroupedRows;
          };

          const groupedRows = groupUpRecursively(rowModel.rows, 0);

          const parentsOfLeafs: any[] = [];
          const leafRowSetIds = new Set();

          const func = getParentsOfLeafNodesWrapper(parentsOfLeafs, leafRowSetIds);

          groupedRows.forEach((r) => {
            const depth = 0;
            func(r, null, depth);
          });

          groupedRows.forEach((subRow) => {
            groupedFlatRows.push(subRow);
            groupedRowsById[subRow.id] = subRow;
          });

          return {
            rows: parentsOfLeafs,
            flatRows: groupedFlatRows,
            rowsById: groupedRowsById,
          };
        },
        getMemoOptions(table.options, 'debugTable', 'getGroupedRowModel', () => {
          table._queue(() => {
            table._autoResetExpanded();
            table._autoResetPageIndex();
          });
        }),
      );
  }
  return getGroupedRowModelInner();
}

function groupBy<TData extends RowData>(rows: Row<TData>[], columnId: string) {
  const groupMap = new Map<any, Row<TData>[]>();

  return rows.reduce((map, row) => {
    const resKey = `${row.getGroupingValue(columnId)}`;
    const previous = map.get(resKey);
    if (!previous) {
      map.set(resKey, [row]);
    } else {
      previous.push(row);
    }
    return map;
  }, groupMap);
}
