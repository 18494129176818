/* eslint-disable no-console */
import { Table } from '@tanstack/react-table';
import React, { useState } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { Input, Button, Checkbox } from 'spoton-lib';

import styles from './SetFilter.module.scss';
import { StringToBooleanDict } from './types';
import { SET_FILTER } from './constants';

// This Set Filter handles both set and text Filter we should rename this
export function SetFilter({ column }: { table: Table<any>; column: any }) {
  const columnFilterValue = column.getFilterValue();

  const cacheKey = columnFilterValue;

  const sortedUniqueValues = React.useMemo(
    () => {
      const ret = Array.from(column.getFacetedUniqueValues().keys()).sort();

      return Array.from(ret);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cacheKey],
  );

  const initColumnFilterState = () => {
    if (columnFilterValue != null && (columnFilterValue as any).filterType === SET_FILTER) {
      // here we merge the unchecked items from storage with sorted uniques
      // so that the set of possible values is dynamic but the unchecked values
      // remain unchecked.

      const { params } = columnFilterValue as any;

      const newState: StringToBooleanDict = {};
      sortedUniqueValues.forEach((current: any) => {
        newState['' + current] = params['' + current] != null ? params['' + current] : true;
      });

      return newState;
    }

    const newState: StringToBooleanDict = {};
    sortedUniqueValues.forEach((current: any) => {
      newState['' + current] = true;
    });

    return newState as StringToBooleanDict;
  };

  const initialState = React.useMemo(
    () => initColumnFilterState(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cacheKey],
  );

  // state for set filter is just dictionary of columnname->true OR false values
  const [colSet, setColSet] = useState<StringToBooleanDict>(initialState);

  const [checkboxFilter, setCheckboxFilter] = useState('');

  const visibleOptions =
    checkboxFilter !== '' ? Object.keys(colSet).filter((i) => i.includes(checkboxFilter)) : Object.keys(colSet);

  function getUncheckedColumnMap(columnCheckedMap: StringToBooleanDict) {
    const uncheckedColumns = Object.keys(columnCheckedMap).filter((i) => columnCheckedMap[i] === false);

    const uncheckedColumnsMap: StringToBooleanDict = {};

    uncheckedColumns.forEach((current: any) => {
      uncheckedColumnsMap['' + current] = false;
    });

    return uncheckedColumnsMap;
  }

  const valueChecked = (col: any) => {
    return ({ target }: { target: any }) => {
      const newValWithTrues = {
        ...colSet,
        [col]: target.checked,
      };

      const newVal = getUncheckedColumnMap({
        ...colSet,
        [col]: target.checked,
      });

      const filterValue = { filterType: SET_FILTER, params: newVal };
      column.setFilterValue(filterValue);
      setColSet(newValWithTrues);
    };
  };

  function renderRow({ index, key, style }: { index: number; key: any; style: any }) {
    const label = visibleOptions[index];
    return (
      <div key={key} style={style}>
        <Checkbox
          label={label}
          data-testid="MultiEditCheckbox"
          checked={colSet[label] === true}
          className={`${styles.Checkbox}`}
          onChange={valueChecked(label)}
        />
      </div>
    );
  }

  const clearState = () => {
    // update colSet so that everything is checked
    const newColSet: StringToBooleanDict = {};
    Object.keys(colSet).forEach((current: any) => {
      newColSet['' + current] = true;
    });

    setColSet(newColSet);

    const filterValue = {
      filterType: SET_FILTER,
      params: {},
    };

    column.setFilterValue(filterValue);
  };

  const unCheckAll = () => {
    // update colSet so that everything is unchecked
    const newColSet: StringToBooleanDict = {};
    Object.keys(colSet).forEach((current: any) => {
      newColSet['' + current] = false;
    });

    const filterValue = {
      filterType: SET_FILTER,
      params: newColSet,
    };

    column.setFilterValue(filterValue);

    setColSet(newColSet);
  };

  return (
    <>
      <div className={styles.PopoverContentContainer}>
        <Input
          label={''}
          type="text"
          value={checkboxFilter}
          onChange={(e) => {
            setCheckboxFilter(e.target.value);
          }}
          name="customViewName"
          placeholder="Filter options..."
        />

        <div
          className={`${styles.CheckboxContainer} ${
            visibleOptions.length < 40 ? '' : styles['CheckboxContainer___fixedHeight']
          }`}
        >
          {visibleOptions.length < 40 ? (
            visibleOptions.map((i) => {
              return (
                <div key={i} style={{ display: 'flex' }}>
                  <Checkbox
                    label={i}
                    data-testid="MultiEditCheckbox"
                    checked={colSet[i] === true}
                    className={`${styles.Checkbox}`}
                    onChange={valueChecked(i)}
                  />
                </div>
              );
            })
          ) : (
            <AutoSizer>
              {({ height, width }) => (
                <List
                  width={width}
                  height={height}
                  rowHeight={32}
                  rowRenderer={renderRow}
                  rowCount={visibleOptions.length}
                  overscanRowCount={100}
                />
              )}
            </AutoSizer>
          )}
        </div>

        <div className={styles.CheckboxSelectButtons}>
          <Button
            variant="ghost"
            className={`${styles.ClearButton}`}
            style={{ width: '100%' }}
            name="ClearFilter"
            onClick={() => {
              clearState();
            }}
          >
            {'Select All'}
          </Button>
          <Button
            variant="ghost"
            className={`${styles.ClearButton} ${styles.ClearButton__right}`}
            style={{ width: '100%' }}
            name="ClearFilter"
            onClick={() => {
              unCheckAll();
            }}
          >
            {'Deselect All'}
          </Button>
        </div>
      </div>
    </>
  );
}
