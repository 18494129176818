/* eslint-disable @typescript-eslint/naming-convention */
import { Row, Table, flexRender } from '@tanstack/react-table';
import { Virtualizer } from '@tanstack/react-virtual';
import { CSSProperties } from 'react';

import { JUSTIFY_LEFT, JUSTIFY_RIGHT, MIN_CELL_WIDTH_MOBILE } from './constants';
import styles from './SpotonDataTableMobile.module.scss';
import { PrivateColumnDefs, StringToStringDict } from './types';
import commonStyles from './SpotonDataTable.module.scss';
import { getHeaderRowHeight, getRowHeight } from './private/constants';

const GROUPING_CELL_WIDTH = 110;

export function MobileTableRenderer({
  table,
  rows,
  tableClassName,
  rowVirtualizer,
  groupingColumns,
}: {
  table: Table<any>;
  tableClassName: string;
  rows: Row<any>[];
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  groupingColumns: string[];
}) {
  const columnLabelMap: StringToStringDict = table.getHeaderGroups()[0].headers.reduce((acc, header: any) => {
    const colDef = header?.column?.columnDef ?? {};
    return { ...acc, [colDef.accessorKey]: colDef.so_oldHeader ?? colDef.header };
  }, {});

  const groupingColumnsSet = new Set(groupingColumns);

  const groupByColumnHeaders = groupingColumns.map((col: string) => columnLabelMap[col]);

  return (
    <table className={`${tableClassName} ${commonStyles.SpotonTable} ${commonStyles['SpotonTable___borderBox']}`}>
      <thead className={`${commonStyles.Head} ${commonStyles.Head___noBorder}`}>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr
            className={styles.HeaderRowMobile}
            key={headerGroup.id}
            style={{ display: 'flex', width: '100%', height: getHeaderRowHeight(true, groupingColumns.length - 1) }}
          >
            {groupingColumns.length > 1 && (
              <th
                className={`${styles.HeaderCellMobile} ${styles.TableTheadCell}  `}
                style={{
                  width: GROUPING_CELL_WIDTH,
                  minWidth: GROUPING_CELL_WIDTH,
                  maxWidth: GROUPING_CELL_WIDTH,
                }}
              >
                <div
                  style={{
                    width: GROUPING_CELL_WIDTH,
                    minWidth: GROUPING_CELL_WIDTH,
                    maxWidth: GROUPING_CELL_WIDTH,
                  }}
                >
                  <div className={styles.HeaderMetricTextContainer}>
                    <div className={styles.GroupingColumnHeader}>
                      {groupByColumnHeaders.map((column, idx) => (
                        <div key={column + idx} className={styles.GroupingRow}>
                          {column}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </th>
            )}

            {headerGroup.headers
              .map((header: any) => {
                const colDef = header?.column?.columnDef ?? {};
                if (groupingColumnsSet.has(colDef.accessorKey)) {
                  // we'll filter this out later
                  return null;
                }
                return (
                  <th id={colDef.accessorKey} className={`${styles.HeaderCellMobile}`} key={header.id}>
                    <div
                      style={{
                        width: header.getSize(),
                        minWidth: header.column.columnDef.so_fixedWidth ?? MIN_CELL_WIDTH_MOBILE,
                        maxWidth: header.column.columnDef.so_fixedWidth ?? undefined,
                      }}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                  </th>
                );
              })
              .filter((item) => item !== null)}
          </tr>
        ))}
      </thead>
      <tbody
        className={commonStyles.TableBody}
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
        }}
      >
        {rows.map((row: Row<any>) => {
          const cellValueMap = row.original;
          const groupingCellValues = groupingColumns.map((col) => '' + cellValueMap[col]);

          groupingCellValues.shift();

          const groupingCellValueJSX = groupingCellValues.map((value, idx) => (
            <div key={(idx + value) as any} className={styles.GroupingRow}>
              {value as any}
            </div>
          ));

          return (
            <tr
              className={commonStyles.TableRow}
              key={row.id}
              style={{
                transform: `translateY(${(row as any).start}px)`,
                height: getRowHeight(true, groupingColumns.length - 1),
              }}
            >
              {groupingColumns.length > 1 && (
                <td
                  className={`${commonStyles.Cell}  ${styles.StickyCell}`}
                  key={row.id + 'column1'}
                  style={{
                    width: GROUPING_CELL_WIDTH,
                    maxWidth: GROUPING_CELL_WIDTH,
                  }}
                >
                  <div
                    style={{
                      width: GROUPING_CELL_WIDTH,
                      maxWidth: GROUPING_CELL_WIDTH,
                    }}
                    className={styles.HeaderMetricTextContainer}
                  >
                    <div className={styles.GroupingColumnHeader}>{groupingCellValueJSX}</div>
                  </div>
                </td>
              )}

              {row
                .getVisibleCells()
                .map((cell) => {
                  const colDef = cell.column.columnDef as PrivateColumnDefs<any>;
                  if (groupingColumnsSet.has(colDef.accessorKey as string)) {
                    // we'll filter this out later
                    return null;
                  }

                  const alignRight = colDef.so_justifyContent === JUSTIFY_RIGHT;
                  const alignLeft = colDef.so_justifyContent === JUSTIFY_LEFT;

                  const alignmentClass = alignRight
                    ? commonStyles.CellContentContainerAlignRight
                    : alignLeft
                    ? commonStyles.CellContentContainerAlignLeft
                    : undefined;

                  return (
                    <td
                      className={`${commonStyles.Cell} ${alignmentClass} `}
                      key={cell.column.id}
                      style={
                        {
                          minWidth: colDef.so_fixedWidth ?? MIN_CELL_WIDTH_MOBILE,
                          maxWidth: colDef.so_fixedWidth ?? undefined,
                          width: cell.column.getSize(),
                          height: getRowHeight(true, groupingColumns.length - 1),
                        } as CSSProperties
                      }
                    >
                      <div
                        className={`${commonStyles.CellContentContainer} ${commonStyles.CellContentContainer___mobile}`}
                      >
                        <div className={commonStyles.CellContentContainerInner}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </div>
                      </div>
                    </td>
                  );
                })
                .filter((item) => item !== null)}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
