import { checkIsProdOrStaging, getConfigVar } from './config.utils';

function detectMobile() {
  return /Mobi/i.test(window.navigator.userAgent);
}

export function isMobile() {
  const forceDesktopMode = !checkIsProdOrStaging() && getConfigVar('REACT_APP_FORCE_DESKTOP_MODE', false) === 'true';
  if (forceDesktopMode) {
    return false;
  }
  return window.innerWidth < 450 || window.innerHeight < 450 || detectMobile();
}
