import { checkIsProdOrStaging } from './config.utils';

export const FAKE_CUSTOM_VIEWS_DATA = 'sr-5312-customviews-use-fake-data-tmp-2024-09';

export const isFeatureFlagEnabled = (featureFlag: string): boolean => {
  if (checkIsProdOrStaging()) {
    if ((window as any).ALL_LD_FLAGS != null) {
      return (window as any).ALL_LD_FLAGS[featureFlag];
    }
  } else {
    if (featureFlag === FAKE_CUSTOM_VIEWS_DATA) {
      return process.env.REACT_APP_FAKE_CUSTOM_VIEWS_DATA === 'true';
    }
  }
};
