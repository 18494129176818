// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderRenderer_IconButton__Yi74p{width:2rem !important;height:2rem !important}.HeaderRenderer_HeaderTextContainerAlignRight__XYRYB{display:flex;flex-direction:row-reverse;align-items:center;padding-right:5px;text-align:right}.HeaderRenderer_HeaderTextContainerAlignLeft__jZ1xe{display:flex;align-items:center;justify-content:flex-start;padding-left:3px;text-align:left}.HeaderRenderer_HeaderTextContainer__5rLQN{width:100%}.HeaderRenderer_HeaderReverse__e-\\+3C{display:flex;flex-direction:row-reverse}.HeaderRenderer_HeaderNormal__X42O5{display:flex;flex-direction:row}.HeaderRenderer_HeaderJustifyStart__zG40x{justify-content:flex-start}.HeaderRenderer_HeaderJustifyEnd__qbsoL{justify-content:flex-end}`, "",{"version":3,"sources":["webpack://./src/features/common/components/SpotonDataTable/HeaderRenderer.module.scss"],"names":[],"mappings":"AAAA,kCACE,qBAAA,CACA,sBAAA,CAGF,qDACE,YAAA,CACA,0BAAA,CACA,kBAAA,CACA,iBAAA,CACA,gBAAA,CAGF,oDACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,gBAAA,CACA,eAAA,CAGF,2CACE,UAAA,CAGF,sCACE,YAAA,CACA,0BAAA,CAGF,oCACE,YAAA,CACA,kBAAA,CAGF,0CACE,0BAAA,CAGF,wCACE,wBAAA","sourcesContent":[".IconButton {\n  width: 2rem !important;\n  height: 2rem !important;\n}\n\n.HeaderTextContainerAlignRight {\n  display: flex;\n  flex-direction: row-reverse;\n  align-items: center;\n  padding-right: 5px;\n  text-align: right;\n}\n\n.HeaderTextContainerAlignLeft {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  padding-left: 3px;\n  text-align: left;\n}\n\n.HeaderTextContainer {\n  width: 100%;\n}\n\n.HeaderReverse {\n  display: flex;\n  flex-direction: row-reverse;\n}\n\n.HeaderNormal {\n  display: flex;\n  flex-direction: row;\n}\n\n.HeaderJustifyStart {\n  justify-content: flex-start;\n}\n\n.HeaderJustifyEnd {\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IconButton": `HeaderRenderer_IconButton__Yi74p`,
	"HeaderTextContainerAlignRight": `HeaderRenderer_HeaderTextContainerAlignRight__XYRYB`,
	"HeaderTextContainerAlignLeft": `HeaderRenderer_HeaderTextContainerAlignLeft__jZ1xe`,
	"HeaderTextContainer": `HeaderRenderer_HeaderTextContainer__5rLQN`,
	"HeaderReverse": `HeaderRenderer_HeaderReverse__e-+3C`,
	"HeaderNormal": `HeaderRenderer_HeaderNormal__X42O5`,
	"HeaderJustifyStart": `HeaderRenderer_HeaderJustifyStart__zG40x`,
	"HeaderJustifyEnd": `HeaderRenderer_HeaderJustifyEnd__qbsoL`
};
export default ___CSS_LOADER_EXPORT___;
