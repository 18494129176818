// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-header-cell,
.ag-header-group-cell {
    background-color: rgb(245, 245, 246) !important;
    color: white !important;
    box-sizing: border-box !important;
}

.ag-header-cell:hover,
.ag-header-group-cell:hover {
    background-color: #1e90ff !important;
}

/* This will ensure the label doesn't change color on hover */
.ag-header-cell-label,
.ag-header-cell-label:hover {
    background-color: transparent !important;
    color: black !important;
}

.ag-row:nth-child(even) {
    background-color: rgb(245, 245, 246); /* light blue */
}

.ag-row:nth-child(odd) {
    background-color: #ffffff; /* white */
}

.ag-footer-cell {
    font-weight: bold !important;
    background-color: #ffd700 !important; /* gold background for total row */
}

.ag-header-group-text {
    color: black;
}

.edit-inputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 5px;
}

.vertical-input input {
    width: 100%;
}

.groupby-metric-pivot-picker-container {
    display: flex;
    gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/App/styles.css"],"names":[],"mappings":"AAAA;;IAEI,+CAA+C;IAC/C,uBAAuB;IACvB,iCAAiC;AACrC;;AAEA;;IAEI,oCAAoC;AACxC;;AAEA,6DAA6D;AAC7D;;IAEI,wCAAwC;IACxC,uBAAuB;AAC3B;;AAEA;IACI,oCAAoC,EAAE,eAAe;AACzD;;AAEA;IACI,yBAAyB,EAAE,UAAU;AACzC;;AAEA;IACI,4BAA4B;IAC5B,oCAAoC,EAAE,kCAAkC;AAC5E;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,QAAQ;AACZ","sourcesContent":[".ag-header-cell,\n.ag-header-group-cell {\n    background-color: rgb(245, 245, 246) !important;\n    color: white !important;\n    box-sizing: border-box !important;\n}\n\n.ag-header-cell:hover,\n.ag-header-group-cell:hover {\n    background-color: #1e90ff !important;\n}\n\n/* This will ensure the label doesn't change color on hover */\n.ag-header-cell-label,\n.ag-header-cell-label:hover {\n    background-color: transparent !important;\n    color: black !important;\n}\n\n.ag-row:nth-child(even) {\n    background-color: rgb(245, 245, 246); /* light blue */\n}\n\n.ag-row:nth-child(odd) {\n    background-color: #ffffff; /* white */\n}\n\n.ag-footer-cell {\n    font-weight: bold !important;\n    background-color: #ffd700 !important; /* gold background for total row */\n}\n\n.ag-header-group-text {\n    color: black;\n}\n\n.edit-inputs {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    border-radius: 5px;\n}\n\n.vertical-input input {\n    width: 100%;\n}\n\n.groupby-metric-pivot-picker-container {\n    display: flex;\n    gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
