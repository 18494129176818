/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { datadogRum } from '@datadog/browser-rum';
import { QueryClient, MutationCache } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { t } from 'i18next';
import { showToast, ToastVariants } from 'spoton-lib';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      if (!mutation.meta?.skipGlobalOnErrorCallback) {
        showToast({
          content: t('SOMETHING_WENT_WRONG'),
          variant: ToastVariants.DANGER,
        });

        // Error responses should be reported by services, and are
        // already visible in the console/network trace in devtools,
        // but we don't want to silence any JS errors that land here
        if (!isAxiosError(error)) {
          datadogRum.addError(error);
        }
      }
    },
  }),
});
