/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, IconButton, colors, Checkbox } from 'spoton-lib';

import {
  SpotonTable,
  SpotOnTanstackColDefs,
  JUSTIFY_LEFT,
  JUSTIFY_NONE,
  MIN_CELL_WIDTH,
  headerAlignmentFunc,
} from 'features/common/components/SpotonDataTable';

import styles from './CustomViews.module.scss';
import { CustomViewType } from '../../utils/customViews';
import { CustomViewEditor } from '../CustomViewEditor';
import { isMobile } from 'features/common/utils/responsive.utils';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnChooser } from 'features/common/components/SpotonDataTable/ColumnChooser';
import { AppDispatch } from 'app/components/App/App.store';
import { openDataSourceConfiguration, setCurrentCustomView } from 'app/components/App/App.slice';

export function CustomViewsDesktop({
  onRemoveCustomViews,
  onUpdateCustomViews,
}: {
  onRemoveCustomViews: (ids: string[]) => void;
  onUpdateCustomViews: (customViews: CustomViewType[]) => void;
}) {
  const dispatch = useDispatch<AppDispatch>();

  const [editModalOpened, setEditModalOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());
  const [filters, setFilters] = useState<any[]>([]);

  const customViews: CustomViewType[] = useSelector((state: any) => state.app.customViewsData);
  const dataCatalog = useSelector((state: any) => state.app.dataCatalog);

  const createNewCustomViewModalOpened = useSelector((state: any) => state.app.createNewCustomViewModalOpened);

  const [masterSelectChecked, setMasterSelectChecked] = useState(false);

  // make sure that the configured datasource still exists in the data catalog before creating it
  // also lets remove some unnecessary configuration before sending over to tanstack
  const customViewsStripped = customViews
    .filter((i: any) => {
      return i.dataSource in dataCatalog;
    })
    .map((i) => ({ ...i, globalId: 1 }));

  const colDefs = [
    {
      accessorKey: 'globalId',
      header: 'Global Id',
      enableResizing: true,
      so_sortIndex: 10000,
      so_visibleByDefault: false,
    },
    {
      id: 'select',
      accessorKey: 'select',
      header: () => {
        const toggleAllRowsSelected = () => {
          if (masterSelectChecked) {
            setSelectedRows(new Set());
          } else {
            setSelectedRows(new Set(customViewsStripped.map((i) => i.id)));
          }

          setMasterSelectChecked(!masterSelectChecked);
        };

        return (
          <Checkbox
            checked={masterSelectChecked}
            className={`${styles.Checkbox} ${styles.Checkbox___rightMargin}`}
            onChange={toggleAllRowsSelected}
          />
        );
      },
      so_fixedWidth: 50,
      cell: ({ row }: { row: any }) => {
        const rowId = row.original.id;

        const onCheckboxChange = () => {
          const newSet = new Set(selectedRows);

          if (selectedRows.has(row.original.id)) {
            newSet.delete(row.original.id);
          } else {
            newSet.add(row.original.id);
          }

          setSelectedRows(newSet);
        };

        return (
          <Checkbox
            checked={selectedRows.has(rowId)}
            className={`${styles.Checkbox} ${styles.Checkbox___rightMargin}`}
            onChange={onCheckboxChange}
          />
        );
      },
      so_justifyContent: JUSTIFY_NONE,
    },
    {
      accessorKey: 'name',
      cell: (info: any) => {
        return (
          <Link
            onClick={() => {
              dispatch(setCurrentCustomView(info.row.original.id));
            }}
            to={`/custom-view/${info.row.original.id}`}
          >
            {info.getValue()}
          </Link>
        );
      },

      so_oldHeader: 'View Name',
      minSize: MIN_CELL_WIDTH,
      so_filterFn: 'set',
      so_justifyContent: JUSTIFY_LEFT,
      so_filters: ['set', 'text'],
    },
    {
      accessorKey: 'description',
      so_oldHeader: 'Description',
      minSize: MIN_CELL_WIDTH,
      so_filterFn: 'set',
      so_justifyContent: JUSTIFY_LEFT,
      so_filters: ['set', 'text'],
    },
    {
      accessorKey: 'dataSource',
      so_oldHeader: 'Data Source',
      minSize: MIN_CELL_WIDTH,
      so_filterFn: 'set',
      so_justifyContent: JUSTIFY_LEFT,
      so_filters: ['set', 'text'],
    },
    {
      accessorKey: 'dateCreated',
      so_oldHeader: 'Date Created',
      minSize: MIN_CELL_WIDTH,
      so_filterFn: 'set',
      so_justifyContent: JUSTIFY_LEFT,
      so_filters: ['set', 'text'],
    },
    {
      accessorKey: 'categoryList',
      so_oldHeader: 'Categories',
      minSize: MIN_CELL_WIDTH,
      so_filterFn: 'set',
      so_filters: ['set', 'text'],
    },
    {
      accessorKey: 'emailList',
      so_oldHeader: 'Shared With',
      minSize: MIN_CELL_WIDTH,
      so_filterFn: 'set',
      so_filters: ['set', 'text'],
    },
    {
      accessorKey: 'startDate',
      so_oldHeader: 'Start Date',
      minSize: MIN_CELL_WIDTH,
      so_filterFn: 'set',
      so_filters: ['set', 'text'],
    },
    {
      accessorKey: 'endDate',
      enableRowGroup: true,
      so_oldHeader: 'End Date',
      minSize: MIN_CELL_WIDTH,
      so_filterFn: 'set',
      so_filters: ['set', 'text'],
    },
  ].map((i) => {
    const activeFilter = filters.find((filter) => filter.id === i.accessorKey);

    let filterFn = null;
    if (activeFilter != null) {
      filterFn = activeFilter.value?.filterType;
    } else {
      filterFn = i.so_filterFn;
    }

    return {
      ...i,
      ...{ filterFn: filterFn },
      header: i.accessorKey === 'select' ? i.header : headerAlignmentFunc(),
      so_justifyContent: i.accessorKey === 'select' ? i.so_justifyContent : JUSTIFY_LEFT,
    };
  });

  const createNew = () => {
    // TODO dispatch action to open the modal
    // open up the modal
    dispatch(openDataSourceConfiguration('new'));
  };

  const [columnOrder, setColumnOrder] = useState<string[]>(colDefs.map((i) => i.accessorKey as string));

  const [chosenColumns, setChosenColumns] = useState<string[]>(colDefs.map((i) => i.accessorKey as string));

  return (
    <div className={`${styles.CustomViewsLayout}`}>
      <div className={styles.CustomViewHeaderContainer}>
        <div className={styles.CustomViewsHeader}>
          <div className={styles.CustomViewsTitle}>Custom Views ( Early Access )</div>

          {!isMobile() && <Button onClick={createNew}>New Custom View</Button>}
        </div>
      </div>

      <div className={styles.GridContainer}>
        <SpotonTable
          rowHeight={50}
          chosenColumns={chosenColumns.filter((i) => i !== 'globalId') ?? []}
          onChosenColumnsChanged={setChosenColumns}
          columnDefs={colDefs as SpotOnTanstackColDefs<any>[]}
          data={customViewsStripped}
          onColumnOrderChanged={setColumnOrder}
          columnOrder={columnOrder ?? []}
          filters={filters}
          onFiltersChanged={setFilters}
          rowSelectionEnabled={true}
          rowSelectionCount={selectedRows.size}
          isSpecificCustomViewPage={false}
          onRemoveClick={() => {
            setConfirmDeleteOpen(true);
          }}
          onEditClick={() => {
            setEditModalOpen(true);
          }}
          disableMenu={true}
        />
      </div>

      {createNewCustomViewModalOpened && <ColumnChooser open={createNewCustomViewModalOpened} />}
      <Modal
        isOpen={confirmDeleteOpen}
        overlayClassName={styles.ModalOverlay}
        className={styles.ConfirmDeleteCustomViewsModal}
      >
        <div className={styles.ConfirmDeleteCustomViewsModal_header}>
          <div className={styles.HeaderTitle}>Remove Custom Views</div>
          <IconButton
            disableBorder
            size={24}
            color={colors.black}
            onClick={() => setConfirmDeleteOpen(false)}
            name="CloseIcon"
            alt={'CLOSE'}
          />
        </div>
        <div className={styles.ConfirmDeletecustomViewsModal_subtitle}>
          Please confirm you wish to remove all selected/checked Custom Views by pressing the Remove button.
        </div>

        <div className={styles.ConfirmDeleteCustomViewsModal_buttons}>
          <Button onClick={() => setConfirmDeleteOpen(false)} className={styles.Button} variant="secondary">
            CANCEL
          </Button>
          <Button
            disabled={false}
            className={styles.Button}
            onClick={() => {
              onRemoveCustomViews(Array.from(selectedRows));
              setConfirmDeleteOpen(false);
            }}
          >
            REMOVE
          </Button>
        </div>
      </Modal>
      {selectedRows.size > 0 && editModalOpened && (
        <CustomViewEditor
          onClose={() => {
            setEditModalOpen(false);
          }}
          open={editModalOpened}
          customViews={customViewsStripped.filter((i) => selectedRows.has(i.id))}
          onSave={(customViews) => {
            onUpdateCustomViews(customViews);
            setEditModalOpen(false);
          }}
        />
      )}
    </div>
  );
}
