export type CustomViewType = {
  location: string;
  name: string;
  id?: string;
  startDate: Date;
  selectedRange: string;
  endDate: Date;
  dataSource: string;
  dateCreated?: Date;
  chosenColumns: string[];
  description: string;
  emailList: string[];
  categoryList: string[];
  columnOrder: string[];
  filters: any[];
  columnSort: any[];
};

export const isTemplateId = (id) => {
  return id != null && Number.isFinite(Number(id));
};

export type StringToBooleanDict = {
  [key: string]: boolean;
};

export type IdToCustomViewMetadataDict = {
  [key: number]: CustomViewType;
};

export const getDemoCustomViews = (customViewData: CustomViewType[]): CustomViewType[] => {
  if (Array.isArray(customViewData)) {
    return customViewData.filter((i) => isTemplateId(i.id));
  }
};

export const getCustomView = (id: string, customViewData: CustomViewType[]): CustomViewType | undefined => {
  if (customViewData) {
    return customViewData.find((i: CustomViewType) => i.id === id);
  }
};
