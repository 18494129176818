// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SetFilter_Checkbox__547oY{font-size:12px}.SetFilter_Checkbox__547oY label>span{margin:0}.SetFilter_Checkbox___rightMargin__0KnXP{margin-right:.5rem}.SetFilter_PopoverContentContainer__GVD-G{display:flex;flex-direction:column;width:100%;min-width:100%}.SetFilter_FilterPickerButton__haW05{width:100%}.SetFilter_CheckboxContainer__Nz26l{max-height:210px;overflow:auto}.SetFilter_CheckboxContainer___fixedHeight__J8irg{height:210px}.SetFilter_CheckboxSelectButtons__M2qFN{display:flex;padding-block:1rem;padding-inline:5px}.SetFilter_ClearButton__u5qV9{padding-top:5px;justify-content:flex-start}.SetFilter_ClearButton__left__E09D3{justify-content:flex-start}.SetFilter_s__\\+dtPr{justify-content:flex-end}`, "",{"version":3,"sources":["webpack://./src/features/common/components/SpotonDataTable/SetFilter.module.scss"],"names":[],"mappings":"AAAA,2BACE,cAAA,CAIE,sCACE,QAAA,CAIJ,yCACE,kBAAA,CAIJ,0CACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,cAAA,CAGF,qCACE,UAAA,CAGF,oCACE,gBAAA,CACA,aAAA,CAEA,kDACE,YAAA,CAIJ,wCACE,YAAA,CACA,kBAAA,CACA,kBAAA,CAGF,8BACE,eAAA,CACA,0BAAA,CAGF,oCACE,0BAAA,CAGF,qBACE,wBAAA","sourcesContent":[".Checkbox {\n  font-size: 12px;\n\n  label {\n    /* stylelint-disable */\n    & > span {\n      margin: 0;\n    }\n  }\n\n  &___rightMargin {\n    margin-right: 0.5rem;\n  }\n}\n\n.PopoverContentContainer {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  min-width: 100%;\n}\n\n.FilterPickerButton {\n  width: 100%;\n}\n\n.CheckboxContainer {\n  max-height: 210px;\n  overflow: auto;\n\n  &___fixedHeight {\n    height: 210px;\n  }\n}\n\n.CheckboxSelectButtons {\n  display: flex;\n  padding-block: 1rem;\n  padding-inline: 5px;\n}\n\n.ClearButton {\n  padding-top: 5px;\n  justify-content: flex-start;\n}\n\n.ClearButton__left {\n  justify-content: flex-start;\n}\n\n.s {\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Checkbox": `SetFilter_Checkbox__547oY`,
	"Checkbox___rightMargin": `SetFilter_Checkbox___rightMargin__0KnXP`,
	"PopoverContentContainer": `SetFilter_PopoverContentContainer__GVD-G`,
	"FilterPickerButton": `SetFilter_FilterPickerButton__haW05`,
	"CheckboxContainer": `SetFilter_CheckboxContainer__Nz26l`,
	"CheckboxContainer___fixedHeight": `SetFilter_CheckboxContainer___fixedHeight__J8irg`,
	"CheckboxSelectButtons": `SetFilter_CheckboxSelectButtons__M2qFN`,
	"ClearButton": `SetFilter_ClearButton__u5qV9`,
	"ClearButton__left": `SetFilter_ClearButton__left__E09D3`,
	"s": `SetFilter_s__+dtPr`
};
export default ___CSS_LOADER_EXPORT___;
