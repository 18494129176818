import React from 'react';
import { AxiosError } from 'axios';
import { ErrorPage } from 'spoton-lib';

import { IPropTypes, IState } from './ErrorBoundaryRequest.types';

// NOTE: using class based component ONLY because we need support for error boundaries
export class ErrorBoundaryRequest extends React.Component<IPropTypes> {
  state: IState = { errorStatus: null };

  componentDidCatch(error: Error): void {
    if (this.isAxiosError(error) && error.response) {
      this.setState({ errorStatus: error.response.status });
    } else {
      throw error;
    }
  }

  isAxiosError = (error: Error): error is AxiosError => 'isAxiosError' in error;

  render(): JSX.Element {
    const { errorStatus } = this.state;

    if (errorStatus) {
      switch (errorStatus) {
        case 403:
          return <ErrorPage errorCode={'403'} />;
        case 404:
          return <ErrorPage errorCode={'404'} />;
        case 500: // fall through
        default:
          return <ErrorPage errorCode={'500'} />;
      }
    }

    return <>{this.props.children}</>;
  }
}
