/* eslint-disable @typescript-eslint/naming-convention */
import { SpotOnTanstackColDefs } from '../types';

export const formatIfMoreThanTwoDecimals = (value) => {
  // Check if value is a valid number
  if (typeof value === 'number' && !isNaN(value)) {
    // Check if it has more than two decimal places
    if (value !== Math.floor(value) && value.toString().split('.')[1]?.length > 2) {
      // Format to two decimal places if it has more than two decimals
      return parseFloat(value.toFixed(2));
    }
  }
  // Return the original value if it doesn't need formatting
  return value;
};

export const valueFormatters = {
  numberFormatter: (params: any) => {
    const so_params = params?.column?.columnDef as SpotOnTanstackColDefs<any>;

    const options = so_params.so_INTLNumberFormatOptions;
    const locale = so_params.so_localeCode ?? 'en-US';
    const formatter = new Intl.NumberFormat(locale, options);

    return formatter.format(params.getValue());
  },
  dateFormatter: (params: any) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (regex.test(params.getValue())) {
      // its already in the format we want
      const ret = params.getValue();

      return ret;
    }

    const dateValue = new Date(params.getValue());
    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, '0');
    const day = String(dateValue.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  },
};
