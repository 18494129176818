import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import styles from './AppLayout.module.scss';

export function AppLayout() {
  return (
    <div className={styles.AppLayout}>
      <ScrollRestoration />
      <div className={styles.AppLayout_content}>
        <Outlet />
      </div>
    </div>
  );
}
