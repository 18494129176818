import { useEffect } from 'react';

import { SpotOnTanstackColDefs, StringToBooleanDict } from '../types';

export const useFlexColumns = ({
  table,
  columnVisibility,
  columnDefs,
  width,
  grouping,
  chosenColumns,
  isMobile = false,
}: {
  table: any;
  columnVisibility: StringToBooleanDict;
  columnDefs: SpotOnTanstackColDefs<any>[];
  width: number;
  grouping: string[];
  chosenColumns: string[];
  isMobile?: boolean;
}) => {
  useEffect(() => {
    function flexColumns() {
      // get all columns that dont have any fixed width columns so we can
      // take the remainder and divvy up their widths to take a fraction of the
      // screen width

      const dynamicColumnsBeforeMobileFilter = columnDefs.filter(
        (i) => i.so_fixedWidth == null && columnVisibility[i.accessorKey as string] === true,
      );

      const dynamicColumns = !isMobile
        ? dynamicColumnsBeforeMobileFilter
        : dynamicColumnsBeforeMobileFilter.filter((i) => !grouping.includes(i.accessorKey as string));

      const fixedColumns = columnDefs.filter(
        (i) =>
          i.so_fixedWidth != null &&
          typeof i.so_fixedWidth === 'number' &&
          columnVisibility[i.accessorKey as string] === true,
      );

      const fixedColumnWidth = fixedColumns.reduce((acc, current) => {
        const val = current.so_fixedWidth;
        if (val != null) {
          return acc + val;
        }
        return acc;
      }, 0);

      const widthMinusMobileStickyColumn = !isMobile ? width : width - 110;

      const stretchWidth = (widthMinusMobileStickyColumn - fixedColumnWidth) / dynamicColumns.length - 2;

      dynamicColumns.forEach((colDef) => {
        colDef.size = stretchWidth;
      });

      fixedColumns.forEach((colDef) => {
        colDef.size = colDef.so_fixedWidth;
      });

      const columnSizingState = dynamicColumns.reduce((acc, current) => {
        return {
          ...acc,
          [(current as any).accessorKey]: stretchWidth,
        };
      }, {});

      table.setState((prevState: any) => ({
        ...prevState,
        ['columnSizing']: columnSizingState,
      }));
    }

    // this code allows columns to grow up to the screen width and shrink down
    // to their min size as the screen width changes
    flexColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, columnDefs, grouping, chosenColumns]);
};
