/* eslint-disable @typescript-eslint/naming-convention */
import { DebouncedInput } from './DebouncedInput';
import { SpotOnTanstackColDefs } from './types';

const getMinMax = (rows: any[], accessorKey: string) => {
  const values = rows.map((row) => row.getValue(accessorKey));
  return [Math.min(...values), Math.max(...values)];
};

export function NumberFilter({ column, table }: { column: any; table: any }) {
  // check to see if the column has a value formatter function
  const so_params: SpotOnTanstackColDefs<any> = column.columnDef as SpotOnTanstackColDefs<any>;

  const timing = so_params.so_filterTiming ?? 'preAgg';

  const [postAggMin, postAggMax] = timing === 'postAgg' ? getMinMax(table.getRowModel().rows, column.id) : ['', ''];

  // some day it will be great if user can choose the timing with a checkbox
  //  but for now we derive timing from column definition metadata
  const columnFilterValue = column.getFilterValue() ?? {
    filterType: 'inclusiveNumericRange',
    params: { min: '', max: '', filterTiming: timing },
  };

  let newMinFormatted = null;
  let newMaxFormatted = null;

  const min = timing === 'preAgg' ? Number(column.getFacetedMinMaxValues()?.[0] ?? '') : Number(postAggMin);
  const max = timing === 'preAgg' ? Number(column.getFacetedMinMaxValues()?.[1] ?? '') : Number(postAggMax);

  if (so_params.so_valueFormatter != null) {
    const options = so_params.so_INTLNumberFormatOptions;
    const locale = so_params.so_localeCode ?? 'en-US';
    const formatter = new Intl.NumberFormat(locale, options);

    newMinFormatted = formatter.format(min);
    newMaxFormatted = formatter.format(max);
  } else {
    newMinFormatted = min;
    newMaxFormatted = max;
  }

  const minPlaceHolderValue = `${newMinFormatted}`;
  const maxPlaceHolderValue = `${newMaxFormatted}`;

  return (
    <>
      <DebouncedInput
        label="Min"
        name="min"
        type="number"
        min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
        max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
        value={columnFilterValue.params.min}
        onChange={(value) => {
          const { params } = columnFilterValue;
          const newValue = { ...params, min: value };
          column.setFilterValue({ ...columnFilterValue, params: newValue });
        }}
        placeholder={minPlaceHolderValue}
      />

      <DebouncedInput
        label="Max"
        type="number"
        name="max"
        min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
        max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
        value={columnFilterValue.params.max}
        onChange={(value) => {
          const { params } = columnFilterValue;
          const newValue = { ...params, max: value };
          column.setFilterValue({ ...columnFilterValue, params: newValue });
        }}
        placeholder={maxPlaceHolderValue}
      />
    </>
  );
}
