import { CustomViewType, getDemoCustomViews } from 'features/custom_views/utils/customViews';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './CustomViewsMobile.module.scss';
import { RowButton } from 'spoton-lib';

type CustomViewMobileParams = {
  customView: CustomViewType;
};

function CustomViewMobile({ customView }: CustomViewMobileParams) {
  const navigate = useNavigate();

  return (
    <RowButton
      className={styles.RowButtonNoPadding}
      title={customView.name}
      subtitle={customView.description}
      rightText={customView.dataSource}
      onClick={() => {
        navigate(`/custom-view/${customView.id}`);
      }}
    />
  );
}

export function CustomViewsMobile({ customViewData }) {
  const [searchParams] = useSearchParams();
  const demoLandingPage = searchParams.get('demoLandingPage') === 'true';
  const [customViews] = useState<CustomViewType[]>(
    demoLandingPage ? getDemoCustomViews(customViewData) : customViewData,
  );

  return (
    <>
      <div className={styles.CustomViewsMobileContent}>
        <div className={styles.CustomViewsTitle}>Custom Views ( Early Access )</div>
        <div className={styles.ViewContainer}>
          {customViews.map((i) => (
            <CustomViewMobile key={i.id} customView={i} />
          ))}
        </div>
      </div>
    </>
  );
}
