import { Row, Table } from '@tanstack/react-table';
import { Virtualizer } from '@tanstack/react-virtual';
import { MobileTableRenderer } from './SpotonDataTableMobile';
import { DesktopTableRenderer } from './SpotonDataTableDesktop';

export function TableRenderer({
  table,
  rows,
  tableClassName,
  handleDragStart,
  handleDragOver,
  handleOnDrop,
  dragOverId,
  dragDirection,
  dragStart,
  rowVirtualizer,
  groupingColumns,
  isMobile,
  rowHeight,
  isSpecificCustomViewPage,
}: {
  table: Table<any>;
  tableClassName: string;
  handleDragStart: (e: React.DragEvent<HTMLDivElement>) => void;
  handleDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  handleOnDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  dragOverId: string;
  dragDirection: string;
  dragStart: string;
  rows: Row<any>[];
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  groupingColumns: string[];
  chosenColumns: string[];
  isMobile: boolean;
  rowHeight?: number;
  isSpecificCustomViewPage: boolean;
}) {
  if (isMobile) {
    return (
      <MobileTableRenderer
        table={table}
        rows={rows}
        rowVirtualizer={rowVirtualizer}
        tableClassName={tableClassName}
        groupingColumns={groupingColumns}
      />
    );
  }
  return (
    <DesktopTableRenderer
      table={table}
      rows={rows}
      rowVirtualizer={rowVirtualizer}
      tableClassName={tableClassName}
      handleDragStart={handleDragStart}
      handleDragOver={handleDragOver}
      handleOnDrop={handleOnDrop}
      dragOverId={dragOverId}
      dragStart={dragStart}
      dragDirection={dragDirection}
      rowHeight={rowHeight}
      groupingColumns={groupingColumns}
      isSpecificCustomViewPage={isSpecificCustomViewPage}
    />
  );
}
