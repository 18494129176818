/* eslint-disable @typescript-eslint/naming-convention */

export const ROW_HEIGHT = 31;

export const DRAG_LEFT = 'left';
export const DRAG_RIGHT = 'right';
export const DRAG_UNSET = '';

export const PARENT_CONTAINER_ID = 'container-table-outer-data-table';

const GROUPING_LINE_HEIGHT = 23;

const HEADER_GROUPING_LINE_HEIGHT = 20;

const getMobileRowHeight = (groupingColumnLength: number | undefined) => {
  if (groupingColumnLength === 1) {
    return 30;
  }
  return GROUPING_LINE_HEIGHT * (groupingColumnLength ?? 0);
};

const getMobileHeaderHeight = (groupingColumnLength: number | undefined) => {
  if (groupingColumnLength === 1) {
    return 40;
  }
  const ret = HEADER_GROUPING_LINE_HEIGHT * (groupingColumnLength ?? 0);

  return ret < 60 ? 60 : ret;
};

export const getRowHeight = (
  isMobile: boolean,
  groupingColumnLength: number | undefined,
  rowHeightOverride?: number,
) =>
  isMobile && groupingColumnLength != null ? getMobileRowHeight(groupingColumnLength) : rowHeightOverride ?? ROW_HEIGHT;

export const getHeaderRowHeight = (isMobile: boolean, groupingColumnLength: number | undefined) =>
  isMobile && groupingColumnLength != null ? getMobileHeaderHeight(groupingColumnLength) : ROW_HEIGHT;
