import debounce from 'lodash.debounce';
import { useEffect } from 'react';

export const useSize = ({
  containerElement = document.body,
  debounceTime = 100,
  onWidthChanged,
  onHeightChanged,
}: {
  containerElement: HTMLElement | null;
  debounceTime?: number;
  onWidthChanged?: (width: number) => void;
  onHeightChanged?: (height: number) => void;
}) => {
  useEffect(() => {
    function handleResize(event: any) {
      const cr = event[0].contentRect;

      if (onWidthChanged) {
        onWidthChanged(cr.width);
      }
      if (onHeightChanged) {
        onHeightChanged(cr.height);
      }
    }

    const debouncedHandleResize = debounce(handleResize, debounceTime);

    const resizeObserver = new ResizeObserver(debouncedHandleResize);

    if (containerElement !== null) {
      resizeObserver.observe(containerElement);
    }

    return () => {
      if (containerElement !== null) {
        resizeObserver.disconnect();
      }
    };
  }, [containerElement, debounceTime, onHeightChanged, onWidthChanged]);
};
