/* eslint-disable @typescript-eslint/naming-convention */
export const SET_FILTER = 'set';
export const TEXT_FILTER = 'text';
export const DATE_RANGE_FILTER = 'dateRange';
export const NUMERIC_RANGE_FILTER = 'inclusiveNumericRange';

export const MIN_CELL_WIDTH = 120;
export const MIN_CELL_WIDTH_MOBILE = 90;

export type Justify = 'left' | 'right' | 'none';

export const JUSTIFY_LEFT: Justify = 'left';
export const JUSTIFY_RIGHT: Justify = 'right';
export const JUSTIFY_NONE: Justify = 'none';
