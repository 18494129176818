import { Table } from '@tanstack/react-table';

export const logVisibleTableVals = <T extends object>(table: Table<T>) => {
  const rowModel = table.getRowModel();

  const visibleColumns = table.getAllColumns().filter((column) => column.getIsVisible());

  const visibleData = rowModel.rows.map((row) => {
    const rowData: { [key: string]: any } = {};
    visibleColumns.forEach((column) => {
      rowData[column.id] = row.getValue(column.id);
    });
    return rowData;
  });

  console.info('Visible Table Values', JSON.stringify(visibleData, null, 2));
};
