/* eslint-disable import/no-unassigned-import */
import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';

import 'i18n/config';

import { routes } from 'app/routes';
import { queryClient } from 'api/queryClient';
import { ErrorBoundaryGeneric, getConfigVar } from 'features';
import './styles.css';
import './index.css';
import { ToastContainer } from 'spoton-lib';

import { Provider, useDispatch } from 'react-redux';
import { AppDispatch, store } from './App.store';
import { customViews, dataSources, locations, sendAsync } from './App.hunks';
import { FAKE_CUSTOM_VIEWS_DATA, isFeatureFlagEnabled } from 'features/common/utils/featureFlags';
import { setMerchantId } from './App.slice';

// so we can peak at the redux store in the console
(window as any).store = store;

function StoreLoader(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const initCustomViews = async () => {
      const merchantId = await sendAsync(
        {
          path: `${getConfigVar('REACT_APP_BFF_BASE_URL')}/custom-views/merchantId`,
          headers: { 'Content-Type': 'application/json' },
          //cacheKey: 'custom-views',
          //cacheDuration: 10 * 1000,
          credentials: 'include',
        },
        {},
      );

      dispatch(setMerchantId(merchantId?.merchantId ?? ''));

      const pathSuffix = isFeatureFlagEnabled(FAKE_CUSTOM_VIEWS_DATA) ? '-fake' : '';

      dispatch(
        dataSources({
          path: `${getConfigVar('REACT_APP_BFF_BASE_URL')}/custom-views/data/datasources${pathSuffix}`,
          headers: { 'Content-Type': 'application/json' },
          //cacheKey: 'dataSources',
          //cacheDuration: 10 * 1000,
          // credentials: 'include',
          credentials: 'include',
        }),
      );

      dispatch(
        customViews({
          path: `${getConfigVar('REACT_APP_BFF_BASE_URL')}/custom-views/${pathSuffix}`,
          headers: { 'Content-Type': 'application/json' },
          //cacheKey: 'custom-views',
          //cacheDuration: 10 * 1000,
          credentials: 'include',
        }),
      );

      dispatch(
        locations({
          path: `${getConfigVar('REACT_APP_BFF_BASE_URL')}/custom-views/data/locations${pathSuffix}`,
          headers: { 'Content-Type': 'application/json' },
          //cacheKey: 'locations',
          //cacheDuration: 10 * 1000,
          credentials: 'include',
        }),
      );
    };

    initCustomViews();
  }, [dispatch]);

  return <div></div>;
}

export function App(): JSX.Element {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <StoreLoader />
        <ErrorBoundaryGeneric>
          <ToastContainer containerId="toast-container" limit={3} />
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={routes} />
          </QueryClientProvider>
        </ErrorBoundaryGeneric>
      </Provider>
    </React.StrictMode>
  );
}
