import { ColumnFilter } from '@tanstack/react-table';
import styles from './FilterBar.module.scss';
import { FilterItem } from './FilterItem';
import { SpotOnTanstackColDefs } from './types';
import { useState } from 'react';

export function FilterBar({
  table,
  columnDefs,
  filters,
}: {
  filters: ColumnFilter[];
  table: any;
  columnDefs: SpotOnTanstackColDefs<any>[];
}) {
  // each filter will store its state in this object
  const [filterState, setFilterState] = useState<any[]>(initializeFilterState(filters));

  // everytime we remove a filter, we need to re-render the filter bar so that the filter item indexes are correct
  // we use this count as part if the key to force a re-render of the items in their new postions
  const [removeFilterCount, setRemoveFilterCount] = useState(0);

  const removeFilter = (_column: string, index: number) => {
    const { columnFilters } = table.getState();
    const filteredFilters = columnFilters.filter((_i: any, idx: number) => idx !== index);
    table.setColumnFilters(filteredFilters);
    setRemoveFilterCount(removeFilterCount + 1);
    setFilterState(initializeFilterState(filteredFilters));
  };

  function onClearInactiveFilter(index: number) {
    const newFilterState = filterState.filter((_i: any, idx: number) => idx !== index);
    setRemoveFilterCount(removeFilterCount + 1);
    setFilterState(newFilterState);
  }

  // filter state is a mix of tanstack filter state ( stuff we persist ) and local state ( stuff we dont persist )
  // for example, we dont persist whether or not filter popup is open.
  function initializeFilterState(filters: ColumnFilter[]) {
    // when we initialize we start off with only tanstack state. this object is passed to each filter for
    // it to manage its own state
    return [...filters.map((filter) => ({ activeColumn: filter.id })), {}];
  }

  const onAddFilter = (column: string) => {
    // the tail is always the last "Add Filter" chip. This is the index where the new item will be inserted.
    const withoutTail = filterState.slice(0, filterState.length - 1);

    setFilterState([...withoutTail, { activeColumn: column, popoverOpen: true }, {}]);
  };

  function updateFilterState(index: number, state: any) {
    if (index < 0 || index >= filterState.length) {
      console.error(`updateFilterState: index ${index} out of bounds`);
      return;
    }

    const newFilterState = [...filterState];
    newFilterState[index] = state;
    setFilterState(newFilterState);
  }

  const filterItems = filterState.map((filter, i) => (
    <FilterItem
      isLastFilter={i === filterState.length - 1}
      key={`removeFilterCount${removeFilterCount}, item + ${i}`}
      table={table}
      columnDefs={columnDefs}
      index={i}
      onRemoveFilter={removeFilter}
      onAddFilter={onAddFilter}
      filterState={filter}
      updateFilterState={updateFilterState}
      onClearInactiveFilter={onClearInactiveFilter}
    ></FilterItem>
  ));

  return <div className={styles.FilterBar}>{filterItems}</div>;
}
