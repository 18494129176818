import React from 'react';
import { Input } from 'spoton-lib';
// A debounced input react component
export function DebouncedInput({
  value: initialValue,
  label,
  onChange,
  debounce = 500,
  type = 'number',
  ...props
}: {
  value: string | number;
  label: string;
  type: string;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  const firstTimeEmptyText = React.useRef(true);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    if (value === '' && firstTimeEmptyText.current === true) {
      firstTimeEmptyText.current = false;
      return;
    }
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Input
      type={type}
      label={label}
      value={value}
      placeholder={props.placeholder}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      name={label}
    />
  );
}
