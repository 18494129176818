import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { ErrorPage } from 'spoton-lib';

import { AppLayout } from '../components/AppLayout';
import { CustomViews } from 'features/custom_views/components/CustomViews';
import { CustomView } from '../../features/custom_views/components/CustomView';

/**
 * Prefix all routes if using a MFE, so that we get the correct routing from
 * our root application.
 *
 * The root application will mount the application to a specific route, in
 * those cases we will need to add a prefix so that the routes in this
 * application continue to match.
 */

export const routesold = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AppLayout />}>
      <Route path="*" element={<ErrorPage errorCode="404" />} />
    </Route>,
  ),
);

export const routes = createBrowserRouter(
  [
    {
      path: '/',
      element: <CustomViews />,
      errorElement: <ErrorPage errorCode="404" />,
    },
    {
      path: '/custom-views',
      element: <CustomViews />,
      errorElement: <ErrorPage errorCode="404" />,
    },
    {
      path: '/custom-view/:id',
      element: <CustomView />,
    },
  ],
  { basename: '/custom-views' },
);
