import { createAsyncThunk } from '@reduxjs/toolkit';

// Helper function to check cache expiration
const isCacheExpired = (timestamp: number, duration: number): boolean => {
  return Date.now() > timestamp + duration;
};

export async function sendAsync(
  {
    method = 'GET',
    path,
    body,
    headers,
    cacheKey, // must be set if you want to cache the data
    cacheDuration, // must be set if you want to cache the data
    credentials,
  }: {
    method?: string;
    path: string;
    body?: any;
    headers?: any;
    credentials?: RequestCredentials;
    cacheKey?: string;
    cacheDuration?: number;
  },
  { rejectWithValue = null },
) {
  // does it exist in the cache already and is it still valid?

  const cacheEnabled = cacheKey != null && cacheDuration != null;

  if (cacheEnabled) {
    // Check localStorage
    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (!isCacheExpired(timestamp, cacheDuration)) {
        return data; // Return cached data if it's not expired
      }
    }
  }

  try {
    const response = await fetch(`${path}`, { method, headers, body, credentials });
    const data = await response.json();

    // Save to localStorage
    if (cacheEnabled) {
      localStorage.setItem(
        cacheKey,
        JSON.stringify({
          data,
          timestamp: Date.now(),
        }),
      );
    }

    return data as any; // Returns the user data which will be passed to `fulfilled`
  } catch (error) {
    if (rejectWithValue) {
      return rejectWithValue('Failed to fetch.'); // Custom error message
    }
  }
}

export const customViews = createAsyncThunk('customViews/fetchAll', sendAsync);
export const dataSources = createAsyncThunk('dataSources/fetchAll', sendAsync);
export const locations = createAsyncThunk('locations/fetchAll', sendAsync);
export const saveCustomView = createAsyncThunk('customViews/saveCustomView', sendAsync);

export const bulkRemoveCustomViews = createAsyncThunk('customViews/bulkRemove', sendAsync);
export const bulkUpdateCustomViews = createAsyncThunk('customViews/bulkUpdate', sendAsync);
